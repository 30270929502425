import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Use hex codes.",
    },
    {
      title: "Go all out on design with custom CSS styling.",
    },
  ];

  const data2 = [
    {
      title: "Provide course access to users directly after purchase.",
    },
  ];

  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Change Admin & Student Dashboards",
    },
    {
      avatar: <FaRegClock />,
      title: "Customize Your eCommerce Site",
    },
    {
      avatar: <FaMedal />,
      title: "Adjust Brand Texts such as Footers, Emails, & More",
    },
    {
      avatar: <FaRegStar />,
      title: "Create Landing Pages & Control Site Navigation",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">Visual Branding & Customization</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Make Your eLearning Platform Truly Yours</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Fully customize the design of your LMS in just a few clicks.
                    From attaching your logo to changing color themes and using
                    your own domain and more—it’s all possible & easy to do with
                    WrightCo Academy.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-39.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-40.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Attach your Logo and Brand Colors</h4>
                  <p className="text-p-16">
                    Align your LMS with your brand visuals by uploading a logo
                    and changing the theme of your platform, starting with menu,
                    background, and text colors.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Customize Your LMS Menus, Text, Buttons, and more...
            </h4>
            <p className="text-p-16 text-white">
              Fully customize the menus, buttons, and text sections in the LMS
              to better represent your company and training type.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-41.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-42.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Use your Domain</h4>
                  <p className="text-p-16">
                    Use a custom domain with the LMS so that learners aren’t
                    distracted by other brand names. If you don’t own a domain
                    and don’t want one—that’s okay—just use ours
                    (yoursite.aomlms.com).
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={20}>
            <Row justify={"center"} align={"middle"}>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4 className="text-white">
                    Notify your Learners in style with Email Templates
                  </h4>
                  <p className="text-p-16 text-white">
                    Connect with your favorite webinar tool or host training in
                    person. Integrate with Zoom and MS Teams so that learners
                    can join live virtual classrooms without leaving the LMS.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="trsnpButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-43.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-44.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Translate to another language</h4>
                  <p className="text-p-16">
                    Translate your LMS from English to Spanish or French with
                    the click of a button. Training in a different language
                    that's not available on- demand? We will create the
                    translation for you.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
