import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row,
  Avatar,
  Typography,
  Layout,
  Checkbox,
  Card,
  Form,
  Input,
  Select,
  InputNumber,
  TimePicker,
} from "antd";
import { useNavigate } from "react-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";
import { RATES,COMISSSION } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { SUBJECTS, CONTENT_TYPE } from "../../config/constants/index";
import swal from "sweetalert";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//icons
import { FaLongArrowAltLeft } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { BsFiles } from "react-icons/bs";
import dayjs from "dayjs";

function Rates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    coachingRate: 0,
  });
  const [comissions, setComissions] = useState({
    coachingCommission: 0,
    tutoringCommission: 0,
  });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      getMyRates();
      getComissions();
    }
  }, []);

  console.log("comissions",comissions)

  const getMyRates = () => {
    try {
      Get(RATES?.getMyRates, token).then((response) => {
        if (response?.status) {
          setRates(response?.data?.rates);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  
  const getComissions = () => {
    try {
      Get(COMISSSION.getComission, token).then((response) => {
        if (response?.status) {
          setComissions(response?.data);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateRates = () => {
    setLoading(true);

    if(!rates?.hourlyRate || !rates?.hourlyRate || !rates?.hourlyRate){
      setLoading(true);
      swal("Oops!", "rates cannot be zero", "error");
      return;
    }

    let data = {
      coachId: user._id,
     ...rates
    };

    Post(RATES?.setRates, data, token, null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);
          swal("Success!", "Rates Updated Successfully", "success");
          setLoading(false);
          setEditMode(false);
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Hourly Rates</>}
        </Typography.Title>
        <FaLongArrowAltLeft
          style={{
            fontSize: "30px",
            color: "#333d47",
            position: "absolute",
            left: 150,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={() => navigate(-1)}
        />
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                minHeight:"400px"
              }}
            >

              <Row>
              <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "25px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 20,
                    }}
                  >
                    Rates
                  </Typography.Title>
              </Row>
              <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    Average Hourly Rates
                  </Typography.Title>
                  {!editMode ? (
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 20,
                      }}
                    >
                      $ {rates?.hourlyRate} / Hour
                    </Typography.Text>
                  ) : (
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      value={rates?.hourlyRate}
                      onChange={(e) => setRates({ ...rates, hourlyRate: e })}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      placeholder="Enter salary"
                      className="signupFormInput signupSelectBox"
                    />
                  )}
                </Col>
                <Col xs={24} md={8}>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    Training Hourly Rates
                  </Typography.Title>
                  {!editMode ? (
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 20,
                      }}
                    >
                      $ {rates?.tutoringRate} / Hour
                    </Typography.Text>
                  ) : (
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      value={rates?.tutoringRate}
                      onChange={(e) => setRates({ ...rates, tutoringRate: e })}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      placeholder="Enter salary"
                      className="signupFormInput signupSelectBox"
                    />
                  )}
                </Col>
                <Col xs={24} md={8}>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    Consulting Hourly Rates
                  </Typography.Title>

                  {!editMode ? (
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 20,
                      }}
                    >
                      $ {rates?.coachingRate} / Hour
                    </Typography.Text>
                  ) : (
                    <InputNumber
                      style={{ width: "100%" }}
                      size="large"
                      value={rates?.coachingRate}
                      onChange={(e) => setRates({ ...rates, coachingRate: e })}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      placeholder="Enter salary"
                      className="signupFormInput signupSelectBox"
                    />
                  )}
                </Col>
              </Row>
              <br />
              <Row>
                {!editMode ? (
                  <Button
                    type="primary"
                    className="loginButton"
                    onClick={() => setEditMode(true)}
                  >
                    {"Edit Rates"}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="loginButton"
                    onClick={() => updateRates()}
                  >
                    {loading ? "Loading..." : "Update Rates"}
                  </Button>
                )}
              </Row>
              <br/><br/>
              <Row>
              <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "25px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 20,
                    }}
                  >
                    Comission
                  </Typography.Title>
              </Row>
             {comissions ?  <Row gutter={[20, 20]}>
                <Col xs={24} md={8}>
                <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                   Consulting Commission
                  </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 20,
                      }}
                    >
                       {comissions?.coachingCommission} %
                    </Typography.Text>
                </Col>
                <Col xs={24} md={8}>
                <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                   Training Commission
                  </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{
                        fontSize: "14px",
                        color: "black",
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 20,
                      }}
                    >
                       {comissions?.tutoringCommission} %
                    </Typography.Text>
                </Col>
                </Row> :   <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 20,
                    }}
                  >
                    No Comissions Set
                  </Typography.Text>}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Rates;
