import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
  message,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import {
  UPLOADS_URL,
  COURSE,
  ADMIN,
  CATEGORIES,
} from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { ImageUrl } from "../../helpers/Functions";
const { Option } = Select;
const { Dragger } = Upload;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function CourseAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    const res = await Get(`${CATEGORIES.getAllcategories}`, token, {
      limit: "100",
    });

    setCategories(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        COURSE.toggleStatus + "/" + course._id,
        token,
        {}
      );
      const newUser = { ...course };

      newUser.isActive = !course.isActive;
      setModalOpen(false);
      setCourse(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    formObject.append("features", values.features.toString().split(","));

    for (const key in values) {
      if (key !== "image" && key !== "features") {
        const item = values[key];
        formObject.append(key, item);
      }
    }
    fileList.forEach((file) => {
      formObject.append("files", file.originFileObj);
    });

    setLoading(true);
    Post(COURSE.addCourse, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Course Added Successfully", "success");
          navigate(-1);

          setEditMode(false);
          setImageNew();
        } else {
          setLoading(false);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setLoading(false);
  };

  const uploadOptions = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 10,
    multiple: true,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        setFileList(info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(file) {
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return false;
      }
      return true;
    },
  };

  return (
    <Layout className="configuration">
      <div className="boxDetails">
        <Row
          className="whiteBackground"
          style={{ backgroundColor: "white", justifyContent: "center" }}
        >
          <Col xs={24} md={22}>
          <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "100px 20px",
              }}
            >
            <Row style={{ padding: "10px 20px" }}>
              <Col
                xs={24}
                md={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaArrowLeft
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  onClick={() => navigate(-1)}
                />
                &emsp;
                <h1 className="pageTitle" style={{ margin: 0 }}>
                  Add Course
                </h1>
              </Col>
            </Row>
            <br />

            <Form
              layout="vertical"
              name="basic"
              className="contactForm"
              labelCol={{
                span: 0,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row style={{ padding: "20px" }}>
                <Col xs={24} md={18}>
                  <Row>
                    <Form.Item name="image">
                      {/* <Dragger   
                      showUploadList={false}
                      style={{ position: "relative" }}
                      beforeUpload={(file) => {
                        setImageNew(URL.createObjectURL(file));
                        return false;
                      }}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibited from uploading company data or other
      banned files.
    </p>
  </Dragger> */}

                      <Upload
                        name="image"
                        showUploadList={false}
                        style={{ position: "relative" }}
                        beforeUpload={(file) => {
                          setImageNew(URL.createObjectURL(file));
                          return false;
                        }}
                      >
                        {imageNew ? (
                          <Image
                            preview={false}
                            src={
                              imageNew
                                ? imageNew
                                : !course?.image
                                ? // ? "/images/avatar.png"
                                  ImageUrl("avatar.png")
                                : UPLOADS_URL + "/" + course?.image
                            }
                            height={300}
                            width={500}
                            style={{ borderRadius: "10px", objectFit: "cover" }}
                          />
                        ) : (
                          <div
                            style={{
                              height: 300,
                              width: 500,
                              padding: 20,
                              display: "flex",
                              textAlign: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#fafafa",
                              border: "2px dashed #dadada",
                              borderRadius: "10px",
                            }}
                          >
                            {" "}
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ fontSize: "30px" }} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Support for a single or bulk upload. Strictly
                              prohibited from uploading company data or other
                              banned files.
                            </p>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  </Row>

                  <br />

                  <Row gutter={20}>
                    <Col xs={12} sm={12}>
                      <Upload {...uploadOptions}>
                        <Button icon={<UploadOutlined />}>
                          Click to Upload
                        </Button>
                      </Upload>
                    </Col>
                  </Row>

                  <br />
                  <br />

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Course Code :
                      </Typography.Title>

                      <Form.Item
                        name="courseCode"
                        initialValue={course?.courseCode}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input  Course Code",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter CourseCode"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Title :
                      </Typography.Title>

                      <Form.Item
                        name="title"
                        initialValue={course?.title}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Title"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Description:
                      </Typography.Title>

                      <Form.Item
                        name="description"
                        initialValue={course?.description}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Course description",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Description"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Category :
                      </Typography.Title>

                      <Form.Item
                        name="category"
                        initialValue={course?.category._id}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select course category",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Category"
                          className="signupSelectBox"
                        >
                          {categories?.map((item, index) => {
                            return (
                              <Option
                                disabled={item.status !== "ACTIVE"}
                                value={item._id}
                              >
                                {item.title}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Start Date :
                      </Typography.Title>

                      <Form.Item
                        name="startDate"
                        initialValue={dayjs(course?.startDate)}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please select  start Date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          size="large"
                          placeholder="Enter start Date"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Duration :
                      </Typography.Title>

                      <Form.Item
                        name="duration"
                        initialValue={course?.duration}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input course duration",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Duration"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Features :
                      </Typography.Title>

                      <Form.Item
                        name="features"
                        initialValue={course?.features}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Features",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Features"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={12}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        Price :
                      </Typography.Title>

                      <Form.Item
                        name="price"
                        initialValue={course?.price}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Please input Price",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Price"
                          className="signupFormInput"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: 30 }}>
                    <>
                      {" "}
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Add Course"}
                      </Button>
                      &emsp;
                      <Button
                        className="fontFamily1"
                        style={{
                          marginTop: "0px",
                          padding: "10px 30px",
                          cursor: "pointer",
                          color: "black",
                          borderRadius: "5px",
                          height: "auto",
                          border: "1px solid #203657",
                          fontWeight: "bold",
                        }}
                        ghost
                        size="large"
                        onClick={(e) => {
                          e.preventDefault();
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  </Row>
                </Col>
              </Row>
            </Form>

            <br />
            <br />
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          // src="../images/question.png"
          src={ImageUrl("question.png")}
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {course?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Do You Want To {course?.isActive ? "Deactivate" : "Activate"} This
          User?
        </Typography.Text>
      </Modal>

      <br />
      <br />
    </Layout>
  );
}
export default CourseAdd;
