import React from "react";

import { Col, Row, Typography, Layout, Card, Image, Rate, Button } from "antd";
import { ImageUrl } from "../../helpers/Functions";
import { useNavigate } from "react-router";

const items = [
  {
    title: "B2B Customer Portals",
    image: "new-31.png",
    link: "/b2bPortal",
  },
  {
    title: "B2B Training",
    image: "new-51.png",
    link: "/b2bTraining",
  },
  {
    title: "Compliance Training",
    image: "new-66.png",
    link: "/complianceTraining",
  },
  {
    title: "Continuing Education",
    image: "new-60.png",
    link: "/continuingEducation",
  },
  {
    title: "Cource Integration APi",
    image: "new-45.png",
    link: "/courceintegrationAPi",
  },
  {
    title: "Customer Stories",
    image: "new-90.png",
    link: "/CustomerStories",
  },
  {
    title: "Customer Training",
    image: "new-56.png",
    link: "/customerTraining",
  },
  {
    title: "Employee Development",
    image: "new-78.png",
    link: "/employeeDevelopment",
  },
  {
    title: "Flexible Ecommerce",
    image: "new-22.png",
    link: "/flexibleEcommerce",
  },
  {
    title: "Instructor Led Training",
    image: "new-10.png",
    link: "/instructorLedTraining",
  },
  {
    title: "Learner Reports",
    image: "new-18.png",
    link: "/learnerReports",
  },
  {
    title: "Pricing Plans",
    image: "new-89.png",
    link: "/pricingPlans",
  },
  {
    title: "Self Paced Course",
    image: "new-4.png",
    link: "/selfPacedCourse",
  },
  {
    title: "Visual Branding",
    image: "new-39.png",
    link: "/visualBranding",
  },
  // Add more items as needed
];

function AboutUs() {
  const navigate = useNavigate();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Services</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}

      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "30px 0",
        }}
      >
        <Col xs={24} md={20}>
          <Row
            style={{ justifyContent: "center", alignItems: "center" }}
            gutter={[32, 32]}
            className="gutter-0"
          >
            {items.map((item, index) => (
              <Col xs={24} sm={12} md={12} lg={8} key={index}>
                <Card
                  className="populr-card"
                  cover={
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={ImageUrl(item.image)}
                    />
                  }
                >
                  <h5>{item.title}</h5>
                  <div style={{ padding: "20px 0" }}>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="loginButton"
                      onClick={() => navigate(item.link)}
                      style={{ width: "100%" }}
                    >
                      View Cource
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default AboutUs;
