import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data = [
    {
      title: "Zoom.",
    },
    {
      title: "Microsoft Teams.",
    },
    {
      title: "GoToWebinar and GoToMeeting.",
    },
    {
      title: "Vimeo",
    },
  ];
  const data2 = [
    {
      title: "Assign an instructor to class.",
    },
    {
      title: "Choose class start and end times.",
    },
    {
      title: "Include online live classes inside of a self-paced course.",
    },
  ];
  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Sell Course Seats Online",
      des: "Create in-person classes that users can register for online. Schedule class dates, location, and assign instructors.",
    },
    {
      avatar: <FaRegClock />,
      title: "Keep Track Of Your Attendees",
      des: "Update and manage attendance. Add or remove students from class.",
    },
    {
      avatar: <FaMedal />,
      title: "Enroll Users Into Courses",
      des: "Enroll students into classes manually, in bulk, or offer self-enrollment.",
    },
    {
      avatar: <IoIosMail />,
      title: "Send Email Reminders",
      des: "Automate emails to notify users of important details after enrolling into a course.",
    },
  ];

  const data4 = [
    {
      title: "Log in and train directly after purchasing a course online.",
    },
    {
      title: "Organizations can purchase training in bulk for their company.",
    },
  ];

  const cardData = [
    {
      image: "new-6.png",
      title: "“Software with tons of features”",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.",
      name: "Adam P.",
      role: "Model Teaching",
    },
    {
      image: "new-7.png",
      title: "“The software is user-friendly...”",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less norma.",
      name: "Karen B.",
      role: "1st Choice Real Estate",
    },
    {
      image: "new-8.png",
      title: "“Easy to use and learn...”",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here",
      name: "Bryanna S.",
      role: "Diabetes Ed",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {
                      <>
                        {" "}
                        Host Webinars, Live Online Classes, And In-Person
                        Training
                      </>
                    }
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Engage your learners in real-time using webinars, in-person
                    classes, and online courses. Schedule, host, and manage
                    instructor-led training with one platform.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-10.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={20}>
            <Row justify={"center"} align={"middle"}>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("coursebanner2.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Connect Your Video Conferencing Apps</h4>
                  <p className="text-p-16">
                    Connect with webinar tools to schedule, host, and manage
                    instructor-led training using only one platform. WrightCo
                    academy supports different video conferencing apps:
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <h4 className="text-38">
              Deliver Education & Courses In-Person Or Online
            </h4>
            <p className="text-p-16">
              You can deliver and manage instructor-led training in different
              ways using WrightCo Academy—report on attendance and user
              engagement later.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-11.png")}
                  />
                  <h5>Create Recurring Classes</h5>
                  <p>
                    Choose a location, instructor, how often a class reoccurs,
                    and the date a class starts and ends. Recurring classes can
                    be online or in-person.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-12.png")}
                  />
                  <h5>Host One-Time Webinars</h5>
                  <p>
                    No more juggling between different platforms to create and
                    manage Webinars. Users can join a webinar directly inside of
                    the LMS.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-13.png")}
                  />
                  <h5>Teach In-Person Courses</h5>
                  <p>
                    Schedule, sell registrations for, and manage in-person
                    classes with the WrightCo Academy software. In-person
                    courses can be one-time or reccuring.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Create Engaging Online Classrooms</h4>
                  <p className="text-p-16">
                    Host one-time or recurring online classes. Live classrooms
                    are great for teaching curriculum, engaging users, and
                    answering questions in real-time.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data2}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-14.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <h4 className="text-38 text-white">
              Manage In-Person And Online Training With The Same Tool
            </h4>
            <p className="text-p-16 text-white">
              Sell and manage in-person training classes with the same platform
              that you use for on-demand courses and webinars.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-15.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                          description={<p className="list-des">{item.des}</p>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Sell Training Course Online</h4>
                  <p className="text-p-16">
                    Sell accredited training to businesses, individuals, and
                    Continuing Education providers. Sell courses directly on the
                    platform with built-in eCommerce, or integrate with an
                    existing store of yours like Shopify.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-16.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={20}>
            <Row justify={"center"} align={"middle"}>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-17.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4 className="text-white">Do You Have LMS Questions? Or Custom eLearning Needs?</h4>
                  <p className="text-p-16 text-white">
                    Our #1 goal is to help you find a learning platform that
                    works for you. If you have questions about our software or
                    our services - let’s answer those.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="trsnpButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="trsnpButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Create & Manage On-Demand eLearning</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
