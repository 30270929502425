import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Train individuals or organizations.",
    },
    {
      title:
        "Employees can log in to their platform using company credentials.",
    },
  ];

  const data2 = [
    {
      title: "Interactive courses made of video, pdf, SCORM, HTML, and more.",
    },
    {
      title:
        "Single Sign-On for customers to log in using company credentials.",
    },
  ];

  const data3 = [
    {
      avatar: <FaCheckCircle />,
      title: "Start With A Template Or Upload Your Own Design",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Save Certificate Templates And Download Them For Later Use",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Attach Course Credits And Certification Codes",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Include Date Of Issue, Expiration Dates, And More...",
    },
  ];

  const data4 = [
    {
      avatar: <FaCheckCircle />,
      title: "Sell Course Licenses In Bulk",
      des: "and charge organizations with a one-time payment or a subscription model.",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Offer Discounts Using Coupon Codes",
      des: "Number of active Users, Enrollment date, Last-active date.",
    },
  ];

  const data5 = [
    {
      title: "Secure training record storage and automatic data backups.",
    },
  ];

  const data6 = [
    {
      avatar: <FaCheckCircle />,
      title: "Customize Text Fields, Fonts & Sizes, Colors, and more...",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Add Expiration Dates",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Change Canvas Size & Design",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Add An Organization ID",
    },
  ];
  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">Safety & Compliance LMS</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> The Ultimate Platform For Compliance Training</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Maintain compliance in your organization with an easy-to-
                    use training platform. From courses to virtual classes—
                    achieving, maintaining, and reporting on compliance is
                    effortless with WrightCo Academy LMS.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-66.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-67.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Hassle-Free Compliance Training</h4>
                  <p className="text-p-16">
                    Keep your teams secure and up to date on compliance
                    training, with self-paced courses and instructor-led
                    training.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 ">Create Scalable & Consistent Programs</h4>
            <p className="text-p-16 ">
              Maintain consistency in compliance training programs using
              on-demand courses that are available online 24/7, and virtual or
              in-person classes.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-68.png")}
                  />
                  <h5 className="">Courses That Runs On Their Own - 24/7</h5>
                  <p className="">
                    Combine videos, PPTs, and more to create self-paced courses.
                    You can also upload courses to include.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-69.png")}
                  />
                  <h5 className="">Standardized Training Reports</h5>
                  <p className="">
                    Report on compliance records like certifications using
                    professional training reports.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-70.png")}
                  />
                  <h5 className="">Prevent Users from Skipping Ahead</h5>
                  <p className="">
                    Use restrictions to prevent learners from skipping over
                    important information in compliance programs.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Track Compliance Requirements In One Place
            </h4>
            <p className="text-p-16 text-white">
              Get software that keeps track of ongoing safety and compliance
              programs for you. Report on course completions, certifications,
              how much time a learner spends in a course, and more all in one
              place.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-71.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 ">Say Goodbye to Vendor Ties with a</h4>
            <p className="text-p-16 ">
              Visually brand your platform with your company’s colors and brand
              guidelines. 100% eliminate vendor ties.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-72.png")}
                  />
                  <h5 className="">Use your Logo, Colors, and Domain</h5>
                  <p className="">
                    Attach a logo, customize colors, texts, and use your own
                    domain.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-73.png")}
                  />
                  <h5 className="">OSHA & DMCA  approved content</h5>
                  <p className="">
                    Customize your content & platform to be OSHA and GDPR
                    complaint.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-74.png")}
                  />
                  <h5 className="">Create custom email notifications</h5>
                  <p className="">
                    Create custom email templates that are triggered by certain
                    LMS events.
                  </p>
                </div>
              </Col>
            </Row>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22} style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="whiteButton"
                  onClick={() => navigate("/about-us")}
                  style={{ marginLeft: "5px" }}
                >
                  Learn About Our White Label LMS
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Create Certificates and Automate Retraining
            </h4>
            <p className="text-p-16 text-white">
              Create certificates or upload pre-created designs. Automate
              certificate delivery, notify users when their certs expire, and
              save certificate templates.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "20px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-75.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data6}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-76.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Eliminate Compliance- Risks with write co academy</h4>
                  <p className="text-p-16">
                    Protect your company against accidents, lawsuits, and audits
                    with an LMS that automates compliance training and helps you
                    identify who has expired certifications.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data5}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38">
              A Compliance Training- Approved eLearning Platform
            </h4>
            <div className="">
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={ImageUrl("safetytrainingLogo.webp")}
                style={{ maxWidth: "150px" }}
              />
            </div>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "20px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"top"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={3}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-77.png")}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={11}>
                <p className="review-p">
                  “For the past four years, WrightCo Academy has helped us
                  provide certification level training for firefighters and
                  first responders. WrightCo Academy offers the perfect solution
                  for online learning, product sales, marketing, commerce,
                  reporting, analytics, and much more. If you are thinking about
                  how to expand your business to meet your needs, give them a
                  try. You will be glad you did!”
                </p>
                <h6>Matthew T.</h6>
                <span className="tab-green-text" style={{ fontSize: "16px" }}>
                  President Of Safety Training FL
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
