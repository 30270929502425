import React, { useEffect, useState } from "react";
import {
  Form,
  Slider,
  Input,
  Col,
  Row,
  Typography,
  Layout,
  Rate,
  Card,
  Tag,
  Skeleton,
  Table,
  Button,
  Progress,
  message,
  Avatar,
  Image,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import ReactPaginate from "react-paginate";
import {
  UPLOADS_URL,
  AUTH,
  LESSON,
  COACH,
  RATES,
  REVIEWS,
} from "../../config/constants/api";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { RiFileList2Fill } from "react-icons/ri";

//icons
import {
  FaArrowRight,
  FaArrowLeft,
  FaUserAlt,
  FaBox,
  FaUsers,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageUrl } from "../../helpers/Functions";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [liveLessons, setLiveLessons] = useState([]);
  const [students, setStudents] = useState([]);
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    coachingRate: 0,
  });
    const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  const [review, setReview] = useState({
    averageRating: 0,
    totalReviews: 0,
  });


  useEffect(() => {
    if (user) {
      getMyStudents();
    }
  }, []);


  const handlePageChange = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: Number(e.selected) + 1,
    });

    getMyStudents(Number(e.selected) + 1);
  };

  const getMyStudents = async (pageNumber) => {
    setLoading3(true);
    try {
      const response = await Get(COACH.getMyStudents, token, {
        page: "1",
        limit: "10",
      });
      setLoading3(false);
      console.log("response", response);
      if (response?.status) {
        setStudents(response?.data?.students);
      } else {
        // message.error(response.message);
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading3(false);
    }
  };


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<>My Students</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <Row>
              <Card
                className="tutorDetailCard"
                style={{ width: "100%" }}
                bordered={false}
              >
                <Row
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    padding: "50px",
                  }}
                  gutter={30}
                >
                  {loading2 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (<>
                    <Row gutter={[30 ,30]} style={{ margin: "20px 0" }}>
                      {students.length == 0 && (
                        <Row
                          style={{
                            width: "100%",
                            padding: 20,
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "grey",
                              textAlign: "center",
                              marginTop: 0,
                            }}
                          >
                            No Students Found
                          </Typography.Title>
                        </Row>
                      )}
                      {students.length > 0 &&
                        students.map((item) => {
                          return (
                            <Col xs={24} md={8}>
                              <Card
                                bordered={false}
                                style={{
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  filter:
                                    "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))",

                                  borderRadius: "25px",
                                  display: "flex",
                                  padding: "0px 0px",
                                  overflow: "hidden",
                                  flexDirection: "column",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {item.image ? (
                                  <img
                                    alt="example"
                                    src={UPLOADS_URL + "/" + item.image}
                                    style={{
                                      width: "100%",
                                      height: "400px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={ImageUrl("avatar.png")}
                                    style={{
                                      width: "100%",
                                      height: "400px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.3)", // You can adjust the opacity here
                                    zIndex: 1,
                                    borderRadius: "25px",
                                    display: "flex",
                                    padding: "0px",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Row
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "20px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <Col span={14}>
                                      <Typography.Title
                                        className="fontFamily1"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: 600,
                                          color: "black",
                                          textAlign: "center",
                                          margin: 0,
                                          zIndex: 3,
                                        }}
                                      >
                                        {item.firstName + " " + item.lastName}
                                      </Typography.Title>
                                    </Col>
                                    <Col
                                      span={10}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        type="primary"
                                        shape="circle"
                                        style={{
                                          backgroundColor: "#00a79d",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        // onClick={()=> {(item.applicationType == "COACHING" || item.applicationType == "BOTH")  ? navigate("/coach-details/"+item._id) : navigate("/tutor-details/"+item._id)}}
                                        icon={
                                          <FaArrowRight
                                            style={{ color: "white" }}
                                          />
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                    <ReactPaginate
              breakLabel="..."
              nextLabel={<FaArrowRight style={{ color: "grey" }} />}
              pageRangeDisplayed={window.innerWidth > 500 ? 4 : 1}
              marginPagesDisplayed={window.innerWidth > 500 ? 4 : 1} //handle Pa
              onPageChange={handlePageChange}
              pageCount={paginationConfig?.totalPages}
              forcePage={paginationConfig?.pageNumber - 1}
              previousLabel={<FaArrowLeft style={{ color: "grey" }} />}
              renderOnZeroPageCount={null}
              pageClassName="page-item" //m
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="paginationContainer"
              activeClassName="active"
            />
                    </>
                  )}
                 
                </Row>

                <br />
                <br />
              </Card>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Dashboard;
