import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row,
  Avatar,
  Typography,
  Layout,
  Checkbox,
  Card,
  Form,
  Input,
  Radio,
  DatePicker,
  TimePicker
} from "antd";
import { useNavigate } from "react-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";
import { AUTH, SCHEDULE } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { CONTENT_TYPE } from "../../config/constants/index";
import swal from "sweetalert";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//icons
import { FaLongArrowAltLeft } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { FcPrevious, FcNext } from "react-icons/fc";
import dayjs from "dayjs";
import { useEffect } from "react";

const responsive = {
  0: { items: 2 },
  568: { items: 4 },
  1024: { items: 7 },
};

function Schedule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [days, setDays] = useState([
    { day: "Sunday", selected: false, dayNo: 0,slots:[] },
    { day: "Monday", selected: false, dayNo: 1,slots:[] },
    { day: "Tuesday", selected: false, dayNo: 2,slots:[] },
    { day: "Wednesday", selected: false, dayNo: 3,slots:[] },
    { day: "Thursday", selected: false, dayNo: 4,slots:[] },
    { day: "Friday", selected: false, dayNo: 5,slots:[] },
    { day: "Saturday", selected: false, dayNo: 6,slots:[] },
  ]);
  const [imageNew, setImageNew] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(0);
  const [slot,setSlot] = useState({startTime:null,endTime:null})
  const carouselRef = useRef();


  useEffect(() => {
    getSchedule()
  },[])


  const handleOnSlideChanged = (event) => {
    setCurrentIndex(event.item);
  };


  function updateStateFromResponse(responseObj) {
    let _days = [...days];
    const selectedDay = _days.find((day) => day.dayNo === responseObj.day);
  
    if (selectedDay) {
      selectedDay.selected = true;
      selectedDay.slots = responseObj.timeSlots ;
    }
  }
  
  const getSchedule = () => {
    try {
      Get(SCHEDULE.getMySchedule, token).then((response) => {
        console.log("response", response);
        if (response?.status) {
          response?.data?.forEach((responseObj) => {
            updateStateFromResponse(responseObj);
          });
          setDays([...days]);
  
          // The 'days' array has been directly updated with selected days and slots
          console.log("updated days", days);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };


  const saveSchedule = () => {
    let error = false;
   
    let _days = days
      .filter((item) => item.selected)
      .map((item) => {
        console.log("item", item.slots.length)
        if(item.slots.length == 0){
          swal("Error!", "Please Enter timeslots for all selected days", "error");
          error = true;
          return;
        }else{
          return { day: item.dayNo, timeSlots: item.slots };
        }
      });

      if(error){
        return;
      }

    let data ={
      coachId:user._id,
      availability:_days
    }

    Post(SCHEDULE.addSchedule, data, token, null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) { 
          swal("Success!", "Schedule Updated Successfully", "success");
          setLoading(false);
          setSlot({startTime:null,endTime:null});
          getSchedule()
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };


  const handleChecked = (checked, indx) => {
    let _days = [...days];
    _days[indx].selected = checked;
    setDays(_days);
  };

  const addSlot = () =>{
    if(!slot.startTime || !slot.endTime){
      swal("Error!", "Select Start and End Time", "error");
      return;
    }
    if(slot.startTime > slot.endTime){
      swal("Error!", "End Time must be greater than Start Time", "error");
      return;
    }
    let _days = [...days]
    _days[selectedDate].slots.push(slot);
    setDays(_days);
    setSlot({startTime:null,endTime:null})
  }

  const removeSlot = (indx) => {
    let _days = [...days]
    _days[selectedDate].slots.splice(indx,1);
    setDays(_days);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Schedule</>}
        </Typography.Title>

        <FaLongArrowAltLeft
          style={{
            fontSize: "30px",
            color: "#333d47",
            position: "absolute",
            left: 150,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={() => navigate(-1)}
        />
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                Availability
              </Typography.Title>
              <Row
                className="flex"
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <AliceCarousel
                  mouseTracking
                  disableButtonsControls
                  disableDotsControls
                  responsive={responsive}
                  ref={carouselRef}
                  controlsStrategy="alternate"
                  onSlideChanged={handleOnSlideChanged}
                >
                  {days.map((item, index) => (
                    <div
                      onClick={() => setSelectedDate(item.dayNo)}
                      // className={
                      //   !isDayAvailable(date.format("dddd")) && "disabledTab"
                      // }
                      style={{
                        width: "100%",
                        height: "60px",
                        cursor: "pointer",
                        backgroundColor:
                          item.dayNo == selectedDate ? "#243d61" : "white",
                        borderRight: index < 6 && "1px solid #dadada",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={item.selected}
                        onChange={(e) => handleChecked(e.target.checked, index)}
                      >
                        {" "}
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            color: item.dayNo == selectedDate ? "white" : "black",
                            textAlign: "left",
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          {item.day}
                        </Typography.Title>
                      </Checkbox>
                    </div>
                  ))}
                </AliceCarousel>
              </Row>
              <br />
              <br />

              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                Slots
              </Typography.Title>
              {days[selectedDate]?.slots.length == 0 &&  <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "14px",
                
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                No Slots Added
              </Typography.Text>}

              <Row >
              {days[selectedDate].slots.length > 0 && days[selectedDate].slots.map((subItem,subIndex) => {
                console.log("subItem", new Date(subItem.startTime))
                return(
                <Col xs={24} md={6} >
                  
                  <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "16px",
               
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                 <CloseCircleOutlined
                 onClick={()=> removeSlot(subIndex)}
                                style={{ color: "#B00000", fontSize: "16px", marginTop: 5, }}
                              /> &emsp; {dayjs(new Date(subItem.startTime)).format('hh:mm A') + " to " + dayjs(new Date(subItem.endTime)).format('hh:mm A') }
                  </Typography.Text>
                 
                  
                </Col>
                )
              })}
              </Row>

              <br/>
              <br/>
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                Add Slots
              </Typography.Title>

              <Row gutter={10}>
                <Col xs={12} md={4}>
                <TimePicker size="large" style={{width:"100%"}} placeholder="Start Time" value={slot.startTime} onChange={(e)=> setSlot({...slot,startTime:e})} format={"hh:mm:A"} />
                </Col>
                <Col xs={12} md={4}>
                <TimePicker  size="large" style={{width:"100%"}}  placeholder="End Time"  value={slot.endTime} onChange={(e)=> setSlot({...slot,endTime:e})} format={"hh:mm:A"} />
                </Col>
                <Col xs={12} md={5}>
                  <Button style={{backgroundColor:"#243d61",color:"white"}} size="large" onClick={()=>addSlot()}>
                    Add
                  </Button>
                </Col>
              </Row>
              <br/>

              <Row justify="center">
              <Button
                    type="primary"
                    className="loginButton"
                    onClick={() => saveSchedule()}
                  >
                    {loading ? "Loading..." : "Save Schedule"}
                  </Button>
              </Row>

              


            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Schedule;
