import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Log in with company credentials using Single Sign-on.",
    },
    {
      title:
        "Notify external systems when employees complete training courses.",
    },
    {
      title: "Share badges & certificates with LinkedIn and Accredible.",
    },
  ];

  //   const data2 = [
  //     {
  //       title: "Interactive courses made of video, pdf, SCORM, HTML, and more.",
  //     },
  //     {
  //       title:
  //         "Single Sign-On for customers to log in using company credentials.",
  //     },
  //   ];

  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Track new hires progress",
      des: "Accurately track the progress of new hires in an onboarding program.",
    },
    {
      avatar: <FaRegClock />,
      title: "Automate HR",
      des: "Automate HR paperwork and reduce new-hire expenses.",
    },
    {
      avatar: <FaMedal />,
      title: "Guide employees",
      des: "Guide employees into professional development after their onboarding.",
    },
  ];
  // const data4 = [
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Sell Course Licenses In Bulk",
  //     des: "and charge organizations with a one-time payment or a subscription model.",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Offer Discounts Using Coupon Codes",
  //     des: "Number of active Users, Enrollment date, Last-active date.",
  //   },
  // ];

  const data5 = [
    {
      title: "Test knowledge retention with interactive assignments.",
    },
    {
      title: "Employees can train when they’re available & ready to learn.",
    },
  ];

  // const data6 = [
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Customize Text Fields, Fonts & Sizes, Colors, and more...",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Add Expiration Dates",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Change Canvas Size & Design",
  //   },
  //   {
  //     avatar: <FaCheckCircle />,
  //     title: "Add An Organization ID",
  //   },
  // ];

  const stories = [
    {
      id: 1,
      title: "Transforming Training: How RTI Thrived with WrightCo Academy's LMS",
      image: "new-92.png",
    },
    {
      id: 2,
      title: "Innovative Learning: Revolutionizing Employee Development",
      image: "new-93.png",
    },
    {
      id: 3,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-94.png",
    },
    {
      id: 4,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-95.png",
    },
    {
      id: 5,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-96.png",
    },
    {
      id: 6,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-97.png",
    },
    {
      id: 7,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-98.png",
    },
    {
      id: 8,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-99.png",
    },
    {
      id: 9,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-100.png",
    },
    {
      id: 10,
      title: "Scaling Excellence: The Future of Online Education",
      image: "new-101.png",
    },
    // Add more stories as needed
  ];
  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">Customer Stories</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {
                      <>
                        {" "}
                        Read How Our Customers Manage Training And Grow Their
                        Businesses
                      </>
                    }
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Learn how our customers manage professional development with
                    AOM: Training Customers, Businesses, and Employees. Say no
                    more to cookie-cutter platforms with poor support!
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-90.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38">
              What Do Customers Love About WrightCo Academy?
            </h4>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-91.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38">More Customer Stories</h4>
            <p className="text-p-16">
              Read how our customers use WrightCo Academy to manage eLearning,
              scale Professional Development, and sell courses B2B and B2C.
            </p>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={16}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              {stories.map((story, index) => (
                <Col key={index} xs={24} sm={24} md={10}>
                  <Link target="_blank" to={"https://wrightcoblog.com/"}>
                  <div className="stories-cards">
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={ImageUrl(story.image)}
                    />
                    <div className="stories-cards-content">
                      <h5>{story.title}</h5>
                    </div>
                  </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
