import React, { useState } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
  FaRegStar,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

const faqs = [
  {
    index: 1,
    text: "Who is the most ideal customer for AOM?",
    innerText:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 2,
    text: "What is your customer support like? What help do I get?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 3,
    text: "How do I decide which plan is best for me?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 4,
    text: "Can you build me an LMS with specific features?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 5,
    text: "Can you help us migrate from another platform?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 6,
    text: "What integrations do you support?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 7,
    text: "Can I do both B2C and B2B with the platform, including eCommerce?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 8,
    text: "How long does it take to be up and running with the platform?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 9,
    text: "Where can we learn more about your platform features?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    index: 10,
    text: "I have other questions, where can I talk to someone?",
    innerText:
      "21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Log in with company credentials using Single Sign-on.",
    },
    {
      title:
        "Notify external systems when employees complete training courses.",
    },
    {
      title: "Share badges & certificates with LinkedIn and Accredible.",
    },
  ];

  //   const data2 = [
  //     {
  //       title: "Interactive courses made of video, pdf, SCORM, HTML, and more.",
  //     },
  //     {
  //       title:
  //         "Single Sign-On for customers to log in using company credentials.",
  //     },
  //   ];

  const data3 = [
    {
      avatar: <GiHamburgerMenu />,
      title: "Track new hires progress",
      des: "Accurately track the progress of new hires in an onboarding program.",
    },
    {
      avatar: <FaRegClock />,
      title: "Automate HR",
      des: "Automate HR paperwork and reduce new-hire expenses.",
    },
    {
      avatar: <FaMedal />,
      title: "Guide employees",
      des: "Guide employees into professional development after their onboarding.",
    },
  ];


  const data4 = [
    {
      avatar: <FaCheckCircle />,
      title: "Instructional Design",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Migration",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Marketing Website Development",
    },
  ];
  const data5 = [
    {
      avatar: <FaCheckCircle />,
      title: "Everything in Professional Plan",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Unlimited Users",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Dedicated Customer Success team",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Unlimited customizations and features. You ask. We build.",
    },
  ];

  const data6 = [
    {
      avatar: <FaCheckCircle />,
      title: "All Core Features",
    },
    {
      avatar: <FaCheckCircle />,
      title: "Build Custom features. Limits Apply",
    },
    {
      avatar: <FaCheckCircle />,
      title: "24-7 helpdesk support and Premium Customer Success team",
    },
  ];

  const CustomRow = ({ faq }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

    return (
      <Row justify="center">
        <Col xs={24} className="bg-white">
          <div className="flex flex-col justify-center items-center bg-[#00A79D] bg-opacity-70 mb-4 rounded-[10px] overflow-hidden">
            <div
              className="flex justify-between items-center px-6 py-4 w-full cursor-pointer bg-[#228a83]"
              onClick={toggleExpand}
            >
              <p className="font-popin text-2xl max-sm:text-xs text-white font-medium">
                {faq.text}
              </p>
              <TiArrowSortedDown
                className={`text-white min-w-[28px] min-h-[28px] pl-2 ${
                  expanded ? "transform rotate-180" : ""
                }`}
              />
            </div>
            <div
              className={`border-t border-white ${
                expanded
                  ? "max-h-[1000px] opacity-100 transition-all duration-500 px-6 py-4"
                  : "max-h-0 opacity-0 transition-all duration-500"
              }`}
            >
              <p className="font-euclid text-2xl max-sm:text-xs text-white font-normal leading-relaxed">
                {faq.innerText}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section className="bg-white top-bottom-padding">
        <Row
          justify={"center"}
          style={{ textAlign: "center", paddingBottom: "40px" }}
        >
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38 ">Pricing Plans</h4>
            <p className="text-p-16 ">
              Tired of cookie cutter platforms ? Your search ends here. Get
              started and begin exploring your customizable B2B training
              platform
            </p>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0", margin: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0 bg-green"
              style={{ padding: "20px 0" }}
            >
              <Col xs={24} sm={24} md={12}>
                <h4 className="text-38 text-white">Professional Plan</h4>
                <p className="text-p-16 text-white">
                  The best B2B LMS platform with all the features you need.
                </p>
                <p className="text-p-16 text-white">Starts at $999/Month</p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="whiteButton"
                  onClick={() => navigate("/about-us")}
                  style={{ marginLeft: "5px" }}
                >
                  Get Started
                </Button>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data6}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0", margin: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0 bg-green"
              style={{ padding: "20px 0" }}
            >
              <Col xs={24} sm={24} md={12}>
                <h4 className="text-38 text-white">Enterprise Plan</h4>
                <p className="text-p-16 text-white">
                  Need a custom learning platform for unlimited users? If you
                  can dream it, we’ll build it for you.
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="whiteButton"
                  onClick={() => navigate("/about-us")}
                  style={{ marginLeft: "5px" }}
                >
                  Talk to Us
                </Button>
              </Col>
              <Col xs={24} md={10}>
                <div className="list-box">
                  <List
                    itemLayout="horizontal"
                    dataSource={data5}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={
                            <span className="list-icon">{item.avatar}</span>
                          }
                          title={<h6 className="list-h6">{item.title}</h6>}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-blue top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"top"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={10}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Frequently Asked Questions</h4>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14}>
                {faqs?.map((faq, index) => (
                  <CustomRow key={index} faq={faq} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <h4>eLearning Services</h4>
                  <p className="text-p-16">
                    We also offer supplemental eLearning services that you can
                    purchase in addition to WrightCo Academy .
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheck />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Learn More
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-89.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
