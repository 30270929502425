import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Button, Row, Avatar, Typography, Layout, Card, Form, Input, Radio, Upload } from "antd";
import { useNavigate } from "react-router";
import { UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { AUTH, COACH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { CONTENT_TYPE } from "../../config/constants/index"
import swal from "sweetalert";

//icons
import {
  FaArrowRight,
  FaArrowLeft,
  FaUserAlt,
  FaBox,
  FaUsers,
} from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb"
import { ImageUrl } from "../../helpers/Functions";

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState()

  React.useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  console.log("imageNew", imageNew)

  const onFinish = (values) => {
    setLoading(true);
    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }



    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }


    Post(COACH.updateProfile, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data)
          dispatch(
            addUser({ user: response.data.data, token: token })
          );

          swal("Success!", "Profile Updated Successfully", "success");
          setLoading(false);
          setEditMode(false);
          setImageNew()
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {

        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> My Profile</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="upcomingButton"
                  onClick={() => navigate("/profile")}
                >
                  Profile
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="completedButton"
                  onClick={() => navigate("/education")}
                >
                  Education
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="scheduleButton"
                  onClick={() => navigate("/schedule")}
                >
                  My Schedule
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="messageButton"
                  onClick={() => navigate("/subjects")}
                >
                  Subjects
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="serviceButton"
                  onClick={() => navigate("/services")}
                >
                  Services
                </Button>

                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="ratesButton"
                  onClick={() => navigate("/rates")}
                >
                  Hourly Rates
                </Button>

                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="reviewButton"
                  onClick={() => navigate("/review")}
                >
                  Rating & Review
                </Button>

                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="tutorButton"
                  onClick={() => navigate("/my-meetings")}

                >
                  My Meetings
                </Button>
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="assesmentButton"
                  onClick={() => navigate("/my-assesments")}

                >
                  My Assesments
                </Button>
              </Row>
            </Card>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >


                <Row>
                  <Col xs={24} md={24}>
                    <Row>
                      {editMode ?


                        <Form.Item

                          name="image"

                        >
                          <Upload
                            name="image"
                            showUploadList={false}
                            style={{ position: "relative" }}

                            beforeUpload={(file) => {
                              setImageNew(URL.createObjectURL(file))
                              return false;
                            }}
                          > <div style={{ padding: "8px", position: "absolute", right: -10, zIndex: 2, bottom: 40, backgroundColor: "#243D62", display: 'flex', maxWidth: "fit-content", color: 'white', borderRadius: "20px" }}><TbCameraPlus /></div> <Avatar
                              size={180}
                              src={
                                imageNew ? imageNew :
                                  !user.image
                                    ? ImageUrl("avatar.png")
                                    : UPLOADS_URL + "/" + user.image
                              }
                            /></Upload>
                        </Form.Item> : <Avatar
                          size={180}
                          src={
                            !user.image
                              ? ImageUrl("avatar.png")
                              : UPLOADS_URL + "/" + user.image
                          }
                        />}
                    </Row>

                    <br />
                    <br />

                    {editMode ? <>
                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="First Name*"
                            name="firstName"
                            initialValue={user.firstName}
                            rules={[
                              {
                                required: true,
                                message: "Please input your first name",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="Enter FullName"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Last Name*"
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: "Please input your last name",
                              },
                            ]}
                            initialValue={user.lastName}
                          >
                            <Input
                              size="large"
                              placeholder="Enter LastName"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Email*"
                            name="email"

                            rules={[
                              {
                                required: true,
                                message: "Please input your email",
                              },
                            ]}
                            initialValue={user.email}
                          >
                            <Input
                              size="large"
                              disabled
                              placeholder="Enter Email Address"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Phone Number*"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please input phone number",
                              },
                            ]}
                            initialValue={user.phone}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Phone Number"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Present Address*"
                            name="address"

                            rules={[
                              {
                                required: true,
                                message: "Please input preasent address",
                              },
                            ]}
                            initialValue={user.address}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Address"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="City*"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please input city name",
                              },
                            ]}
                            initialValue={user.city}
                          >
                            <Input
                              size="large"
                              placeholder="Enter City"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="State*"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please input state",
                              },
                            ]}
                            initialValue={user.state}
                          >
                            <Input
                              size="large"
                              placeholder="Enter State"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label={
                              <>
                                Zip Code <span className="redStar">*</span>
                              </>
                            }
                            name="zip"
                            rules={[
                              {
                                required: true,
                                message: "Please input zip code",
                              },
                            ]}
                            initialValue={user.zip}
                          >
                            <Input
                              size="large"
                              placeholder="Enter Zip Code"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Tag Line*"
                            name="tagLine"
                            rules={[
                              {
                                required: true,
                                message: "Please enter tag line",
                              },
                            ]}
                            initialValue={user.tagLine}
                          >
                            <Input
                              size="large"
                              placeholder="Enter TagLine"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Home Number*"
                            name="homeNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please enter home number",
                              },
                            ]}
                            initialValue={user.homeNumber}
                          >
                            <Input
                              size="large"
                              placeholder="Enter home number"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Social Security Number**"
                            name="socialSecurity"
                            rules={[
                              {
                                required: true,
                                message: "Please input social security number",
                              },
                            ]}
                            initialValue={user.socialSecurity}
                          >
                            <Input
                              size="large"
                              placeholder="Enter social security number"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Alternate Phone Number*"
                            name="alternatePhone"
                            rules={[
                              {
                                required: true,
                                message: "Please enter alternate phone numner",
                              },
                            ]}
                            initialValue={user.alternatePhone}
                          >
                            <Input
                              size="large"
                              placeholder="Enter alternate phone numner"
                              className="signupFormInput"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        {/* <Col xs={24} md={12}>
                    <Form.Item
                      label="Convicted For Felony*"
                      name="felony"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                      initialValue={user.felony}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col> */}
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Detailed Bio*"
                            name="bio"
                            rules={[
                              {
                                required: true,
                                message: "Please input your bio",
                              },
                            ]}
                            initialValue={user.bio}
                          >
                            <Input.TextArea
                              size="large"
                              placeholder="Enter Detailed Bio"
                              className="signupFormInput ContactFormInput2"
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </> : <>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            ID
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?._id}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            First Name
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user.firstName}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Last Name
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.lastName}
                          </Typography.Text>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 40 }}>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Email
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.email}
                          </Typography.Text>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Phone Number
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.phone}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Present Address
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.address}
                          </Typography.Text>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 40 }}>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            City
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.city}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            State
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.state}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Zip Code
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.zip}
                          </Typography.Text>
                        </Col>
                      </Row>

                      {/* <Row style={{ marginTop: 40 }}>
                    <Col xs={12} sm={12}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        Convicted for Felony
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {user?.felony ? "Yes" : "No"}
                      </Typography.Text>
                    </Col>

                    <Col xs={12} sm={6}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        Home Number
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {user?.homeNumber}
                      </Typography.Text>
                    </Col>
                  </Row> */}

                      <Row style={{ marginTop: 40 }}>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Social Security Number
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.socialSecurity}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Home Number
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.homeNumber}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Alternate Phone Number
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.alternatePhone}
                          </Typography.Text>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 40 }}>
                        <Col xs={12} sm={6}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Tag Line
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.tagLine}
                          </Typography.Text>
                        </Col>

                        <Col xs={12} sm={12}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              textAlign: "left",
                              marginTop: 0,
                            }}
                          >
                            Detailed Bio
                          </Typography.Title>
                          <Typography.Text
                            className="fontFamily1"
                            style={{
                              fontSize: "14px",
                              color: "grey",
                              textAlign: "left",
                            }}
                          >
                            {user?.bio}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </>}

                    <Row style={{ marginTop: 30 }}>
                      {editMode && <> <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"

                      >
                        Update Profile
                      </Button>&emsp;&emsp;
                        <Button
                          className="fontFamily1"
                          style={{
                            marginTop: "0px",
                            padding: "10px 30px",
                            cursor: "pointer",
                            color: "black",
                            height: "auto",
                            border: "1px solid #203657",
                            fontWeight: "bold",
                          }}
                          ghost
                          size="large"
                          onClick={(e) => { e.preventDefault(); setEditMode(false) }}
                        >
                          Cancel
                        </Button></>}

                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      &emsp;
                      {/* <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 40px",
                        cursor: "pointer",
                        color: "black",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                    >
                      Join Lesson
                    </Button> */}
                    </Row>
                  </Col>
                </Row>
              </Form>

              {!editMode && <><Button
                type="primary"
                htmlType="button"
                className="loginButton"
                onClick={() => setEditMode(true)}
              >
                Edit Profile
              </Button>   &emsp;&emsp;
                <Button
                  className="fontFamily1"
                  style={{
                    marginTop: "0px",
                    padding: "10px 30px",
                    cursor: "pointer",
                    color: "black",
                    height: "auto",
                    border: "1px solid #203657",
                    fontWeight: "bold",
                  }}
                  ghost
                  size="large"
                  onClick={() => navigate("/change-password")}
                >
                  Change Password
                </Button></>}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Profile;
