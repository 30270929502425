
// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOADS_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"

const { NODE_ENV } = process.env
const { hostname } = window.location

const servers = {
  local: "http://localhost:3012",
  customDev: "https://react.customdev.solutions:3012",
  live: "https://wrightcoacademy.com:3012",
}

var URL;
if (NODE_ENV === "production" && hostname.includes("react.customdev.solutions"))
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("wrightcoacademy.com") ) URL = servers.live;
else URL = servers.local;

export const BASE_URL = `${URL}/api`
export const UPLOADS_URL = `${URL}/Uploads`
export const UPLOAD_URL2 = `${URL}/`
export const SOCKET_URL = `${URL}`


// export const BASE_URL = "http://localhost:3012/api"
// export const UPLOADS_URL = "http://localhost:3012/Uploads"
// export const SOCKET_URL = "http://localhost:3012"



export const AUTH = {
    signin: "/coach/auth/signin",
    signup:"/coach/auth/register",
    emailCode:"/coach/auth/emailVerificationCode",
    verifyCode:"/coach/auth/verifyRecoverCode",
    resetPassword:"/coach/auth/resetPassword",
}
export const FEEDBACK = {
    addFeedback: "/feedback/addFeedback",
}
export const CATEGORIES = {
    getAllcategories: "/category/getAllcategories",
    addCategory: "/category/addCategory",
    updateCategory: "/category/updateCategory/",
    toggleStatus: "/category/toggleStatus/",
    edit:"/category/edit/",
  };

export const USERS = {
    get: "/auth/signin",
    getAllCoaches:"/admin/user/getAllCoaches",
    getAllTutors:"/admin/user/getAllTutors",
    getCoachById:"/admin/user/getCoachById/"
}

export const LECTURES = {
    getAllLectures: "/lecture/getAllLectures",
    getLectureById:"/lecture/getLectureById/",
    updateLecture:"/lecture/updateLecture/",
    addLecture:"/lecture/addLecture"
  };
export const COACH = {
    updateProfile : "/coach/profile/updateProfile",
    changePassword:"/coach/profile/changePassword",
    getMyStudents:"/coach/profile/getMyStudents",
}

export const COURSE = {
    addCourse:"/course/addCourse",
    getAllCourses: "/course/getAllCourses",
    getCourseById: "/course/getCourseById/",
    updateCourse:"/course/updateCourse/"
  };

export const SERVICES = {
    getAll: "/service/getAllServices"
}

export const RATES = {
    getMyRates: "/rates/getMyRates",
    setRates : "/rates/setRates"
}


export const MEETING = {
    addMeeting:"/meeting/addMeeting",
    getAllMeetings: "/meeting/getAllMeetings",
    getAllCoachMeetings: "/meeting/getAllCoachMeetings/",
    getMyMeetings: "/meeting/getMyMeetings",
    getMeetingById: "/meeting/getMeetingById/",
    updateMeeting:"/meeting/updateMeeting/",
    deleteMeeting:"/meeting/deleteMeeting/"
  };

  export const ASSESMENT = {
    addAssesment:"/assesment/addAssesment",
    getAllAssesments: "/assesment/getAllAssesments",
    getAllCoachAssesment: "/assesment/getAllCoachAssesment/",
    getMyAssesments: "/assesment/getMyAssesments",
    getAssesmentById: "/assesment/getAssesmentById/",
    updateAssesment:"/assesment/updateAssesment/",
    deleteAssesment:"/assesment/deleteAssesment/"
  };

  
export const COMISSSION = {
    getComission : "/comission/getComission"
}

export const SCHEDULE = {
    addSchedule: "/schedule/addSchedule",
    getMySchedule:"/schedule/getMySchedule"
}


export const CHAT = {
    createChat: "/chat/createChat",
    getMyChats:"/chat/getMyChats",
}


export const MESSAGE = {
    createMessage: "/message/createMessage",
    getChatMessages:"/message/getChatMessages/",
}


export const REVIEWS={
    getAll:"/review/getAllReviewsByCoachId/",
    getCoachRatings:"/review/getCoachRatings/"
}

export const NOTIFICATION={
    getAllMyNotifications:"/notification/getAllMyNotifications",
    getUnreadNotifications:"/notification/getUnreadNotifications",
    toggleNotification:"/notification/toggleNotification/"
}



export const LESSON={
    bookLesson:"/lesson/addLesson",
    getUpcomingLessons:"/lesson/getAllUpcomingLessons",
    getCompletedLessons:"/lesson/getAllCompletedLessons",
    getLiveLessons:"/lesson/getAllLiveLessons",
    getLessonById:"/lesson/getLessonById/",
    updateLesson:"/lesson/updateLesson/",
    createLessonSignature:"/lesson/createLessonSignature"
}





