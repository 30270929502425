import React, { useEffect, useState } from "react";
import {
  Form,
  Slider,
  Input,
  Col,
  Row,
  Typography,
  Layout,
  Rate,
  Card,
  Tag,
  Skeleton,
  Table,
  Button,
  Progress,
  message,
  Avatar,
  Image,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import ReactPaginate from "react-paginate";
import {
  UPLOADS_URL,
  AUTH,
  LESSON,
  COACH,
  RATES,
  REVIEWS,
} from "../../config/constants/api";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { RiFileList2Fill } from "react-icons/ri";

//icons
import {
  FaArrowRight,
  FaArrowLeft,
  FaUserAlt,
  FaBox,
  FaUsers,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageUrl } from "../../helpers/Functions";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [liveLessons, setLiveLessons] = useState([]);
  const [students, setStudents] = useState([]);
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    coachingRate: 0,
  });

  const [review, setReview] = useState({
    averageRating: 0,
    totalReviews: 0,
  });

  const item = { rating: 4 };

  console.log("students", students);
  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 9 && "0") + (index + 1),
    },
    {
      title: "Lesson ID",
      dataIndex: "lessonId",
      key: "lessonId",
    },
    {
      title: "Student Name",
      dataIndex: "student",
      key: "student",
      render: (item) => <span>{item?.firstName + " " + item?.lastName}</span>,
    },
    {
      title: "Lesson Date",
      dataIndex: "lessonDate",
      key: "lessonDate",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Lesson Charge",
      dataIndex: "charges",
      key: "charges",
      render: (item) => <span>${item}</span>,
    },
    {
      title: "Lesson Type",
      dataIndex: "lessonType",
      key: "lessonType",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (item) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
          onClick={() => navigate("/lesson-detail/" + item)}
        />
      ),
    },
  ];

  const columns2 = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 9 && "0") + (index + 1),
    },
    {
      title: "Lesson ID",
      dataIndex: "lessonId",
      key: "lessonId",
    },
    {
      title: "Student Name",
      dataIndex: "student",
      key: "student",
      render: (item) => <span>{item?.firstName + " " + item?.lastName}</span>,
    },
    {
      title: "Lesson Date",
      dataIndex: "lessonDate",
      key: "lessonDate",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Lesson Charge",
      dataIndex: "charges",
      key: "charges",
      render: (item) => <span>${item}</span>,
    },
    {
      title: "Lesson Type",
      dataIndex: "lessonType",
      key: "lessonType",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Payment",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (item) => (
        <span style={{ color: item ? "#76ba53" : "red" }}>
          {item ? "Paid" : "Pending"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (item) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
          onClick={() => navigate("/lesson-detail/" + item)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (user) {
      getMyRates();
      getReviewCount();
      getUpcomingLessons();
      getLiveLessons();
      getMyStudents();
    }
  }, []);

  const getMyRates = () => {
    try {
      Get(RATES.getMyRates, token).then((response) => {
        if (response?.status) {
          setRates(response?.data?.rates);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getReviewCount = () => {
    try {
      Get(REVIEWS.getCoachRatings + user._id, token).then((response) => {
        if (response?.status) {
          setReview(response?.data);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getMyStudents = async (pageNumber, pageSize, search, reset = false) => {
    setLoading3(true);
    try {
      const response = await Get(COACH.getMyStudents, token, {
        page: "1",
        limit: "3",
      });
      setLoading3(false);
      console.log("response", response);
      if (response?.status) {
        setStudents(response?.data?.students);
      } else {
        // message.error(response.message);
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading3(false);
    }
  };

  const getUpcomingLessons = async (
    pageNumber,
    pageSize,
    search,
    reset = false
  ) => {
    setLoading2(true);
    try {
      const response = await Get(LESSON.getUpcomingLessons, token, {
        page: "1",
        limit: "5",
      });
      setLoading2(false);
      console.log("response", response);
      if (response?.status) {
        setUpcomingLessons(response?.data?.docs);
      } else {
        // message.error(response.message);
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading2(false);
    }
  };

  const getLiveLessons = async (
    pageNumber,
    pageSize,
    search,
    reset = false
  ) => {
    setLoading(true);
    try {
      const response = await Get(LESSON.getLiveLessons, token, {
        page: "1",
        limit: "5",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setLiveLessons(response?.data?.docs);
      } else {
        // message.error(response.message);
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const onSearch = (value) => console.log(value);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<> Dashboard</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={6}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "10px",
            }}
          >
            <Card className="contactCard2" bordered={false}>
              <Row
                style={{
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  size={200}
                  src={
                    !user.image
                      ? ImageUrl("avatar.png")
                      : UPLOADS_URL + "/" + user.image
                  }
                />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "20px",
                    fontWeight: 800,
                    color: "black",
                    textAlign: "left",
                    marginTop: 20,
                  }}
                >
                  {user?.firstName + " " + user?.lastName}
                </Typography.Title>
                <Typography.Text
                  className="fontFamily1"
                  style={{
                    fontSize: "12px",
                    color: "grey",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {user?.bio?.slice(0, 150)} {user?.bio?.length > 150 && "..."}
                </Typography.Text>

                <Row>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#0FB3AF",
                      textAlign: "left",
                      marginTop: 10,
                      marginBottom: 0,
                    }}
                  >
                    {review.averageRating}{" "}
                  </Typography.Title>
                  &emsp;
                  <Rate
                    disabled
                    allowHalf
                    value={review.averageRating}
                    style={{ color: "#FABF35", marginTop: 0 }}
                  />
                </Row>

                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 20,
                  }}
                >
                  {review.totalReviews} Reviews
                </Typography.Title>
                <br />
                {rates.hourlyRate && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="226"
                    height="41"
                    viewBox="0 0 226 41"
                    fill="none"
                  >
                    <path
                      d="M0 0H226L210.031 20.5L226 41H0L16.7292 20.5L0 0Z"
                      fill="#2584c6"
                    />
                    <text
                      className="fontFamily1"
                      x="50%"
                      y="50%"
                      dominant-baseline="middle"
                      text-anchor="middle"
                      fill="#FFFFFF"
                      font-size="14"
                    >
                      Hourly Rates : ${rates.hourlyRate}
                    </text>
                  </svg>
                )}

                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="upcomingButton"
                  onClick={() => navigate("/upcoming-lessons")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <FaUserAlt
                        style={{ fontSize: "12px", color: "#E86AAF" }}
                      />
                    </div>
                  }
                >
                  Upcoming Lessons
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="completedButton"
                  onClick={() => navigate("/live-lessons")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <FaBox style={{ fontSize: "12px", color: "#649BAB" }} />
                    </div>
                  }
                >
                  Live Lessons
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="scheduleButton"
                  onClick={() => navigate("/completed-lessons")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <RiFileList2Fill
                        style={{ fontSize: "14px", color: "#9384CC" }}
                      />
                    </div>
                  }
                >
                  Completed Lessons
                </Button>
                <br />

                <Button
                  type="primary"
                  htmlType="submit"
                  className="messageButton"
                  onClick={() => navigate("/chat")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <BiSolidMessageAltDetail
                        style={{ fontSize: "14px", color: "#6FAF4D" }}
                      />
                    </div>
                  }
                >
                  Message
                </Button>
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="serviceButton"
                  onClick={() => navigate("/students")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <HiUsers style={{ fontSize: "14px", color: "#6ECCD8" }} />
                    </div>
                  }
                >
                  Students
                </Button>
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="serviceButton colr2"
                  onClick={() => navigate("/course-management")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <HiUsers style={{ fontSize: "14px", color: "#6ECCD8" }} />
                    </div>
                  }
                >
                  Courses
                </Button>
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="serviceButton colr3"
                  onClick={() => navigate("/lecture-management")}
                  icon={
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        padding: 7,
                      }}
                    >
                      <HiUsers style={{ fontSize: "14px", color: "#6ECCD8" }} />
                    </div>
                  }
                >
                  Lectures
                </Button>
                <br />

                {/* <Button
                  type="primary"
                  htmlType="submit"
                  className="tutorButton"
                  icon={<div style={{backgroundColor:"white",borderRadius:"20px",padding:7}}>
                   <FaUsers style={{fontSize:'12px',color:"#649BAB"}}/>

                  </div>}
                >
                My Trainers/Coaches
                </Button> */}
                <br />
              </Row>
            </Card>
          </div>
        </Col>
        <Col xs={24} md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <Row>
              <Card
                className="tutorDetailCard"
                style={{ width: "100%" }}
                bordered={false}
              >
                <Row
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    padding: "30px 30px 0 30px",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                    }}
                  >
                    Live Lesson
                  </Typography.Title>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (
                    <Table
                      pagination={false}
                      className="styledTable2"
                      columns={columns}
                      dataSource={liveLessons}
                    />
                  )}
                </Row>

                <Row
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 10,
                    }}
                  >
                    Upcoming Lessons
                  </Typography.Title>

                  {loading2 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (
                    <Table
                      pagination={false}
                      className="styledTable2"
                      columns={columns2}
                      dataSource={upcomingLessons}
                    />
                  )}
                </Row>

                <Row
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                    }}
                  >
                    My Students
                  </Typography.Title>
                  {loading2 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Skeleton active />
                      <br />
                    </div>
                  ) : (
                    <Row gutter={20} style={{ margin: "20px 0" }}>
                      {students.length == 0 && (
                        <Row
                          style={{
                            width: "100%",
                            padding: 20,
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              color: "grey",
                              textAlign: "center",
                              marginTop: 0,
                            }}
                          >
                            No Students Found
                          </Typography.Title>
                        </Row>
                      )}

                      {students.length > 0 &&
                        students.map((item) => {
                          return (
                            <Col xs={24} md={6}>
                              <Card
                                bordered={false}
                                style={{
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  filter:
                                    "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))",

                                  borderRadius: "25px",
                                  display: "flex",
                                  padding: "0px 0px",
                                  overflow: "hidden",
                                  flexDirection: "column",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {item.image ? (
                                  <img
                                    alt="example"
                                    src={UPLOADS_URL + "/" + item.image}
                                    style={{
                                      width: "100%",
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={"./images/avatar.png"}
                                    style={{
                                      width: "100%",
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.3)", // You can adjust the opacity here
                                    zIndex: 1,
                                    borderRadius: "25px",
                                    display: "flex",
                                    padding: "0px",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Row
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      padding: "20px",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <Col span={24}>
                                      <Typography.Title
                                        className="fontFamily1"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: 600,
                                          color: "black",
                                          textAlign: "center",
                                          margin: 0,
                                          zIndex: 3,
                                        }}
                                      >
                                        {item.firstName + " " + item.lastName}
                                      </Typography.Title>
                                    </Col>
                                    {/* <Col
                    span={10}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      style={{
                        backgroundColor: "#00a79d",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      // onClick={()=> {(item.applicationType == "COACHING" || item.applicationType == "BOTH")  ? navigate("/coach-details/"+item._id) : navigate("/tutor-details/"+item._id)}}
                      icon={<FaArrowRight style={{ color: "white" }} />}
                    />
                  </Col> */}
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  )}
                  {students.length > 4 && (
                    <Row style={{ justifyContent: "center" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton"
                        onClick={() => navigate("/students")}
                      >
                        View All
                      </Button>
                    </Row>
                  )}
                </Row>

                <br />
                <br />
              </Card>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Dashboard;
