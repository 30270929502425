import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, FEEDBACK, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import { FaArrowRight } from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Functions";
import Slider from "react-slick";
const { Meta } = Card;
// import router from "next/router";

const items = [
  {
    title: "B2B Customer Portals",
    image: "new-31.png",
    link: "/b2bPortal",
  },
  {
    title: "B2B Training",
    image: "new-51.png",
    link: "/b2bTraining",
  },
  {
    title: "Compliance Training",
    image: "new-66.png",
    link: "/complianceTraining",
  },
  {
    title: "Continuing Education",
    image: "new-60.png",
    link: "/continuingEducation",
  },
  {
    title: "Cource Integration APi",
    image: "new-45.png",
    link: "/courceintegrationAPi",
  },
  {
    title: "Customer Stories",
    image: "new-90.png",
    link: "/CustomerStories",
  },
  // Add more items as needed
];

function Homepage() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    const { firstName, lastName, email, subject, message } = values;
    let data = {
      firstName,
      lastName,
      email,
      subject,
      message,
    };
    Post(FEEDBACK.addFeedback, data)
      .then((response) => {
        setLoading(false);
        if (response?.status) {
          swal("Success!", response?.data?.message, "success");
          form.resetFields();
        } else {
          swal("Oops", response.message, "error");
        }
      })
      .catch((err) => {
        console.log("error", err);
        const message = err?.response?.data?.message || err?.message;
        if (message) {
          swal("Oops", message, "error");
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const testimonials = [
    {
      title: "Great Platform",
      quote:
        "The course quality and affordability make this the best learning platform I’ve come across. I love that I can access my mentor 24/7 for any questions I have—absolutely worth it!",
      name: "Beth Luna",
      rating: 4,
      image: "ellipse1.png",
    },
    {
      title: "Great Platform",
      quote:
        "This platform has completely transformed my learning experience! The one-on-one mentorship is invaluable, and I’ve seen tremendous growth in a short time. Highly recommended!",
      name: "John Doe",
      rating: 5,
      image: "ellipse1.png",
    },
    {
      title: "Great Platform",
      quote:
        "I was blown away by how easy it was to collaborate with others using the whiteboard feature. The courses are top-notch, and I feel fully supported every step of the way.",
      name: "Jane Smith",
      rating: 4,
      image: "ellipse1.png",
    },
    {
      title: "Great Platform",
      quote:
        "Enrolling in the popular courses was the best decision I made for my career! The content is engaging, and the instructors are incredibly knowledgeable. I can’t wait to take more courses!",
      name: "Beth Luna",
      rating: 4,
      image: "ellipse1.png",
    },
    {
      title: "Great Platform",
      quote:
        "The flexibility of learning at my own pace, combined with expert guidance, has been a game-changer. The affordable prices make it easy to keep advancing my skills without financial stress.",
      name: "John Doe",
      rating: 5,
      image: "ellipse1.png",
    },
    {
      title: "Great Platform",
      quote:
        "I appreciate the personalized attention I receive from my mentor. Their insights have helped me grasp complex concepts quickly. I feel confident in my learning journey!",
      name: "Jane Smith",
      rating: 4,
      image: "ellipse1.png",
    },
    // Add more testimonials as needed
  ];

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <Row
        className="AuthBackground"
        style={{
          disply: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={24} md={9}>
          <div className="heroSection">
            <Row style={{ width: "100%", justifyContent: "center" }}>
              <Col xs={20} md={20} className="formWrap">
                <Typography.Title
                  className="fontFamily1 smallTitle"
                  style={{ color: "#333d47" }}
                >
                  {<> Getting Quality Education is now more Easy</>}
                </Typography.Title>

                <Typography.Text
                  className="fontFamily1 smallText"
                  style={{ color: "#333d47" }}
                >
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking
                </Typography.Text>
                <br />
                <br />
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="loginButton"
                  onClick={() => navigate("/about-us")}
                >
                  About Us
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="trsnpButton"
                  onClick={() => navigate("/about-us")}
                  style={{ marginLeft: "5px" }}
                >
                  Join Us Free
                </Button>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={0} sm={0} md={13}>
          <div className="authImageBox">
            <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
              <Col xs={0} sm={12} md={24}>
                <div className="learningImage1" />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* conters start*/}

      <section className="new-green-bg">
        <Row justify={"center"}>
          <Col lg={21}>
          <div className="flex-container home-counter">
              <div className="item">
                <h5>8200</h5>
                <p>Success Stories</p>
              </div>
              <Divider type="vertical" />
              <div className="item">
                <h5>12200</h5>
                <p>Expert Mentors</p>
              </div>
              <Divider type="vertical" />
              <div className="item">
                <h5>50000</h5>
                <p>Hours Course</p>
              </div>
              <Divider type="vertical" />
              <div className="item">
                <h5>70000</h5>
                <p>Active Student</p>
              </div>
            </div>
          </Col>
        </Row>
      </section>

      {/* conters end*/}

      {/* section 2 */}
      <Row
        className=""
        style={{
          backgroundColor: "",
          justifyContent: "center",
          padding: "40px 0",
        }}
      >
        <Col xs={24} md={22}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              // minHeight: "100vh",
              padding: "10px",
            }}
          >
            <Typography.Title
              className="fontFamily1"
              style={{
                fontSize: "35px",
                fontWeight: 600,
                color: "#333d47",
                textAlign: "center",
              }}
            >
              Benefits of online Education
            </Typography.Title>

            <Typography.Text
              className="fontFamily1"
              style={{ fontSize: "16px", color: "grey", textAlign: "center" }}
            >
              {
                <>
                  It is a long established fact that a reader will be distracted
                  by <br /> the readable content of a page when looking
                </>
              }
            </Typography.Text>
            <div className="flex-container home-benefits">
              <div className="item">
                <Card
                  className="coachCard home-coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    width={100}
                    // height={40}
                    src={ImageUrl("ben-1.png")}
                  />
                  <h1>One on One Monitor</h1>
                  <p>
                    Get personalized guidance and track your progress with
                    dedicated one-on-one monitoring.
                  </p>
                </Card>
              </div>
              <div className="item">
                <Card
                  className="coachCard home-coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    width={100}
                    // height={40}
                    src={ImageUrl("ben-2.png")}
                  />
                  <h1>24/7 Mentor </h1>
                  <p>
                    Access expert guidance with round-the-clock mentor support.
                    ensuring you never miss a learning opportunity.
                  </p>
                </Card>
              </div>
              <div className="item">
                <Card
                  className="coachCard home-coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    width={100}
                    // height={40}
                    src={ImageUrl("ben-3.png")}
                  />
                  <h1>Whiteboard</h1>
                  <p>
                    Turn your thoughts into action with a versatile space for
                    real-time collaboration and creativity.
                  </p>
                </Card>
              </div>
              <div className="item">
                <Card
                  className="coachCard home-coachCard"
                  bordered={false}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    width={100}
                    // height={40}
                    src={ImageUrl("ben-4.png")}
                  />
                  <h1>Affordable Price</h1>
                  <p>
                    Achieve your goals without breaking the bank with our
                    budget-friendly pricing plans. affordable pricing for every
                    learner.
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* section 3 */}

      {/* section 2 */}
      <Row
        className=""
        style={{
          backgroundColor: "",
          justifyContent: "center",
          padding: "40px 0 80px 0",
        }}
      >
        <Col xs={24} md={19}>
          <Row gutter={[16, 16]} align={"middle"} className="gutter-0">
            <Col lg={12}>
              <Image
                preview={false}
                alt={"Failed to load image"}
                // width={100}
                // height={40}
                src={ImageUrl("learning2.png")}
              />
            </Col>
            <Col lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  // minHeight: "100vh",
                  padding: "50px",
                }}
              >
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "35px",
                    fontWeight: 600,
                    color: "#333d47",
                  }}
                >
                  We are Always Ensure Best Course for your learning
                </Typography.Title>

                <p
                  className="fontFamily1"
                  style={{
                    fontSize: "16px",
                    color: "grey",
                    padding: "20px 0",
                  }}
                >
                  We're committed to providing the best courses tailored to your
                  learning needs and goals.
                </p>
                <div>
                  <Button
                    type="primary"
                    htmlType="button"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    About Us
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* section 3 */}

      <Row style={{ backgroundColor: "#f2fbfa", justifyContent: "center" }}>
        <Col xs={24} md={19}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              // minHeight: "100vh",
              padding: "80px",
            }}
          >
            <Typography.Title
              className="fontFamily1"
              style={{
                fontSize: "35px",
                fontWeight: 600,
                color: "#333d47",
                textAlign: "center",
              }}
            >
              Our Popular Courses
            </Typography.Title>

            <Typography.Text
              className="fontFamily1"
              style={{
                fontSize: "16px",
                color: "grey",
                textAlign: "center",
              }}
            >
              {<>Explore our most sought-after courses, designed to help you excel in your field.</>}
            </Typography.Text>
          </div>
          <Row
            style={{ justifyContent: "center", alignItems: "center" }}
            gutter={[32, 32]}
            className="gutter-0"
          >
            {items.map((item, index) => (
              <Col xs={24} sm={12} md={12} lg={8} key={index}>
                <Card
                  className="populr-card"
                  cover={
                    <Image
                      preview={false}
                      alt={"Failed to load image"}
                      src={ImageUrl(item.image)}
                    />
                  }
                >
                  <h5>{item.title}</h5>
                  <Rate disabled defaultValue={item.rating} />
                  <div style={{ padding: "20px 0" }}>
                  <Button
                      type="primary"
                      htmlType="button"
                      className="loginButton"
                      onClick={() => navigate(item.link)}
                      style={{ width: "100%" }}
                    >
                      View Course
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "20px 0",
            }}
            gutter={[32, 32]}
            className="gutter-0"
          >
            <Col lg={24}>
              <Button
                type="primary"
                htmlType="button"
                className="loginButton"
                onClick={() => navigate("/about-us")}
              >
                See More
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* section 4 */}

      <section className="students-say">
        <Row style={{ backgroundColor: "", justifyContent: "center" }}>
          <Col xs={24} md={19}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                // minHeight: "100vh",
                padding: "10px",
              }}
            >
              <Typography.Title className="fontFamily1 text-35">
                What our Students say
              </Typography.Title>

              <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "16px",
                  color: "grey",
                  textAlign: "center",
                }}
              >
                {
                  <>
                    Discover firsthand experiences from our students who have
                    transformed their learning journey with us.
                  </>
                }
              </Typography.Text>
            </div>
            <Row
              style={{ justifyContent: "center", alignItems: "center" }}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col lg={24}>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index}>
                      <div className="testicard">
                        <h5>{testimonial.title}</h5>
                        <Image
                          preview={false}
                          alt={"Failed to load image"}
                          width={30}
                          // height={40}
                          src={ImageUrl("coma.png")}
                        />
                        <p>{testimonial.quote}</p>
                        <Divider />
                        <div className="media-object">
                          <Image
                            preview={false}
                            alt={"Failed to load image"}
                            src={ImageUrl(testimonial.image)}
                          />
                          <div>
                            <h6>{testimonial.name}</h6>
                            <Rate disabled defaultValue={testimonial.rating} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="students-say">
        <Row style={{ backgroundColor: "", justifyContent: "center" }}>
          <Col xs={24} md={18}>
            <div className="contact-wraper">
              <Row
                style={{ justifyContent: "center", alignItems: "center" }}
                gutter={[32, 32]}
                className="gutter-0"
              >
                <Col lg={24}>
                  <div className="contact-heading-box">
                    <h2>Get in touch</h2>
                    <p>
                      Reach out, and let's create a universe of possibilities
                      together!
                    </p>
                  </div>
                </Col>
                <Col lg={20}>
                  <div className="home-contact-inner-box">
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col lg={12}>
                        <h5>Let’s connect constellations</h5>
                        <p>
                          Let's align our constellations! Reach out and let the
                          magic of collaboration illuminate our skies.
                        </p>
                        <Form
                          name="basic"
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                          form={form}
                        >
                          <Row gutter={[16, 16]}>
                            <Col lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="firstName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your First Name!",
                                  },
                                ]}
                              >
                                <Input
                                  className="web-input"
                                  placeholder="Enter First Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={12} sm={24} xs={24}>
                              <Form.Item
                                name="lastName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter your last Name!",
                                  },
                                ]}
                              >
                                <Input
                                  className="web-input"
                                  placeholder="Enter Last Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter your email!",
                                  },
                                ]}
                              >
                                <Input
                                  className="web-input"
                                  placeholder="Enter Email"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name="subject"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Subject!",
                                  },
                                ]}
                              >
                                <Input
                                  className="web-input"
                                  placeholder="Enter Subject"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item
                                name="message"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Message",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  rows={4}
                                  className="web-input"
                                  placeholder="Enter Message"
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="loginButton"
                                  style={{ width: "100%" }}
                                >
                                  Send
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                      <Col lg={12} style={{ textAlign: "end" }}>
                        <Image
                          preview={false}
                          alt={"Failed to load image"}
                          src={ImageUrl("contactImg.png")}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
