import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//components imports
import UserAuthCheck from "../../components/AuthCheck/UserAuthCheck";
// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import Homepage from "../../views/homepage";
import Signin from "../../views/signin";
import Signup from "../../views/signup";
import ForgotPassword from "../../views/forget-password-1";
import ForgotPassword2 from "../../views/forget-password-2";
import ForgotPassword3 from "../../views/forget-password-3";
import AboutUs from "../../views/about-us";
import ContactUs from "../../views/contact-us";
import Trainer from "../../views/tutor";
import Coach from "../../views/coach";
import TutorDetails from "../../views/tutor-details";
import CoachDetails from "../../views/coach-details";
import Dashboard from "../../views/dashboard";
import UpcomingLessons from "../../views/upcoming-lessons";
import CompletedLessons from "../../views/completed-lessons";
import BookingLogs from "../../views/booking-logs";
import PaymentLogs from "../../views/payment-logs";
import LessonDetail from "../../views/lesson-detail";
import Chat from "../../views/chat";
import Calander from "../../views/calander";
import MyCalander from "../../views/myCalander";
import Profile from "../../views/profile";
import ChangePassword from "../../views/change-password";
import Education from "../../views/education";
import Schedule from "../../views/schedule";
import Subjects from "../../views/subjects";
import Services from "../../views/Services";
import Rates from "../../views/Rates";
import Review from "../../views/review";
import Lesson from "../../views/lesson";
import LiveLessons from "../../views/live-lessons";
import Notifications from "../../views/notifications";
import Students from "../../views/students";
import MyMeetings from "../../views/my-meetings";
import AddMeeting from "../../views/add-meeting";
import MeetingDetails from "../../views/meeting-details";
import MyAssesments from "../../views/my-assesments";
import AddAssesment from "../../views/add-assesment";
import AssesmentDetails from "../../views/assesment-details";
import FAQ from "../../views/faq";
import Facility from "../../views/facility";
import SelfPacedCourse from "../../views/courses/selfPacedCourse";
import InstructorLedTraining from "../../views/courses/instructorLedTraining";
import LearnerReports from "../../views/courses/learnerReports";
import FlexibleEcommerce from "../../views/courses/flexibleEcommerce";
import B2bPortal from "../../views/courses/b2bPortal";
import VisualBranding from "../../views/courses/visualBranding";
import CourceintegrationAPi from "../../views/courses/courceintegrationAPi";
import B2bTraining from "../../views/courses/b2bTraining";
import CustomerTraining from "../../views/courses/customerTraining";
import ContinuingEducation from "../../views/courses/continuingEducation";
import ComplianceTraining from "../../views/courses/complianceTraining";
import EmployeeDevelopment from "../../views/courses/employeeDevelopment";
import PricingPlans from "../../views/courses/pricingPlans";
import CustomerStories from "../../views/courses/CustomerStories";
import Book from "../../views/courses/book";
import CourseManagement from "../../views/course-management";
import CourseDetails from "../../views/course-management/courseDetails";
import CourseAdd from "../../views/course-management/courseAdd";
import Category from "../../views/course-management/category";
import LectureManagement from "../../views/lecture-management"
import LectureDetails from "../../views/lecture-management/lectureDetails"
import LectureAdd from "../../views/lecture-management/lectureAdd"
import Courses from "../../views/courses"

const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/signin"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              footer={false}
            >
              <Signin />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/signup"
          element={
            <ClientLayout
              head={{ title: "Signup", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              footer={false}
            >
              <Signup />{" "}
            </ClientLayout>
          }
        />
<Route
          path="/lecture-management"
          index
          element={
              <ClientLayout
                head={{
                  title: "Lecture Management",
                  description: "Some Description.",
                }}
              >
                <LectureManagement />
              </ClientLayout>
          }
        />
        <Route
          path="/lecture-details/:id"
          index
          element={
              <ClientLayout
                head={{
                  title: "Lecture Details",
                  description: "Some Description.",
                }}
              >
                <LectureDetails />
              </ClientLayout>
          }
        />
        <Route
          path="/lecture-details/add"
          index
          element={
            <UserAuthCheck>
              <ClientLayout
                head={{
                  title: "Lecture Add",
                  description: "Some Description.",
                }}
              >
                <LectureAdd />
              </ClientLayout>
            </UserAuthCheck>
          }
        />
        <Route
          path="/course-management"
          index
          element={
            <ClientLayout
              head={{
                title: "Course Management",
                description: "Some Description.",
              }}
            >
              <CourseManagement />
            </ClientLayout>
          }
        />
<Route
          path="/course-details/:id"
          index
          element={
              <ClientLayout
                head={{
                  title: "Course Details",
                  description: "Some Description.",
                }}
              >
                <CourseDetails />
              </ClientLayout>
          }
        />
        <Route
          path="/course-details/add"
          index
          element={
              <ClientLayout
                head={{
                  title: "Course Add",
                  description: "Some Description.",
                }}
              >
                <CourseAdd />
              </ClientLayout>
          }
        />
        <Route
          path="/category"
          index
          element={
              <ClientLayout
                head={{
                  title: "Category",
                  description: "Some Description.",
                }}
              >
                <Category />
              </ClientLayout>
          }
        />
        <Route
          path="/change-password"
          element={
            <ClientLayout
              head={{
                title: "Change Password",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ChangePassword />
            </ClientLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <ClientLayout
              head={{ title: "Profile", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Profile />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/education"
          element={
            <ClientLayout
              head={{ title: "Education", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Education />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/subjects"
          element={
            <ClientLayout
              head={{ title: "Subjects", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Subjects />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/students"
          element={
            <ClientLayout
              head={{ title: "Students", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Students />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/notifications"
          element={
            <ClientLayout
              head={{
                title: "Notifications",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Notifications />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/courses"
          element={
            <ClientLayout
              head={{ title: "Services", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Courses />{" "}
            </ClientLayout>
          }
        />
        <Route
          path="/services"
          element={
            <ClientLayout
              head={{ title: "Services", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Services />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/my-meetings"
          element={
            <ClientLayout
              head={{ title: "My Meetings", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MyMeetings />{" "}
            </ClientLayout>
          }
        />
        <Route
          path="/add-meeting"
          element={
            <ClientLayout
              head={{ title: "Add Meeting", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AddMeeting />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/meeting-details/:id"
          element={
            <ClientLayout
              head={{
                title: "Meeting Details",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MeetingDetails />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/my-assesments"
          element={
            <ClientLayout
              head={{
                title: "My assesments",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MyAssesments />{" "}
            </ClientLayout>
          }
        />
        <Route
          path="/add-assesment"
          element={
            <ClientLayout
              head={{
                title: "Add Assesments",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AddAssesment />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/assesment-details/:id"
          element={
            <ClientLayout
              head={{
                title: "Assesments Details",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AssesmentDetails />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/review"
          element={
            <ClientLayout
              head={{ title: "Review", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Review />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/rates"
          element={
            <ClientLayout
              head={{ title: "Rates", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Rates />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/schedule"
          element={
            <ClientLayout
              head={{ title: "Schedule", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Schedule />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              footer={false}
            >
              <ForgotPassword />{" "}
            </ClientLayout>
          }
        />
        <Route
          path="/forgot-password-2"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              footer={false}
            >
              <ForgotPassword2 />{" "}
            </ClientLayout>
          }
        />

        <Route
          path="/forgot-password-3"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              footer={false}
            >
              <ForgotPassword3 />{" "}
            </ClientLayout>
          }
        />
        <Route
          path="/"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Homepage />
            </ClientLayout>
          }
        />

        <Route
          path="/about-us"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <AboutUs />
            </ClientLayout>
          }
        />

        <Route
          path="/contact-us"
          element={
            <ClientLayout
              head={{ title: "Contact Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ContactUs />
            </ClientLayout>
          }
        />

        <Route
          path="/faq"
          element={
            <ClientLayout
              head={{ title: "FAQ", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <FAQ />
            </ClientLayout>
          }
        />
        <Route
          path="/facility"
          element={
            <ClientLayout
              head={{ title: "Facility", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Facility />
            </ClientLayout>
          }
        />

        <Route
          path="/tutor"
          element={
            <ClientLayout
              head={{ title: "Trainer", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Trainer />
            </ClientLayout>
          }
        />

        <Route
          path="/coach"
          element={
            <ClientLayout
              head={{ title: "Consultant", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Coach />
            </ClientLayout>
          }
        />

        <Route
          path="/tutor-details"
          element={
            <ClientLayout
              head={{
                title: "Trainer Details",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <TutorDetails />
            </ClientLayout>
          }
        />

        <Route
          path="/coach-details/:id"
          element={
            <ClientLayout
              head={{
                title: "Consultant Details",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CoachDetails />
            </ClientLayout>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Dashboard />
            </ClientLayout>
          }
        />

        <Route
          path="/upcoming-lessons"
          element={
            <ClientLayout
              head={{
                title: "Upcoming Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <UpcomingLessons />
            </ClientLayout>
          }
        />

        <Route
          path="/completed-lessons"
          element={
            <ClientLayout
              head={{
                title: "Completed Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CompletedLessons />
            </ClientLayout>
          }
        />
        <Route
          path="/live-lessons"
          element={
            <ClientLayout
              head={{
                title: "Upcoming Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <LiveLessons />
            </ClientLayout>
          }
        />

        <Route
          path="/booking-logs"
          element={
            <ClientLayout
              head={{
                title: "Completed Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <BookingLogs />
            </ClientLayout>
          }
        />

        <Route
          path="/payment-logs"
          element={
            <ClientLayout
              head={{
                title: "Completed Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PaymentLogs />
            </ClientLayout>
          }
        />

        <Route
          path="/lesson-detail/:id"
          element={
            <ClientLayout
              head={{
                title: "Upcoming Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <LessonDetail />
            </ClientLayout>
          }
        />

        <Route
          path="/lesson/:id"
          element={
            <ClientLayout
              head={{
                title: "Upcoming Lessons",
                description: "Some Description.",
              }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Lesson />
            </ClientLayout>
          }
        />

        <Route
          path="/chat"
          element={
            <ClientLayout
              head={{ title: "chat", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Chat />
            </ClientLayout>
          }
        />

        <Route
          path="/calander"
          element={
            <ClientLayout
              head={{ title: "chat", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Calander />
            </ClientLayout>
          }
        />

        <Route
          path="/my-calander"
          element={
            <ClientLayout
              head={{ title: "chat", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <MyCalander />
            </ClientLayout>
          }
        />

        <Route
          path="/selfPacedCourse"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <SelfPacedCourse />
            </ClientLayout>
          }
        />

        <Route
          path="/instructorLedTraining"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <InstructorLedTraining />
            </ClientLayout>
          }
        />
        <Route
          path="/learnerReports"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <LearnerReports />
            </ClientLayout>
          }
        />
        <Route
          path="/flexibleEcommerce"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <FlexibleEcommerce />
            </ClientLayout>
          }
        />
        <Route
          path="/b2bPortal"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <B2bPortal />
            </ClientLayout>
          }
        />
        <Route
          path="/visualBranding"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <VisualBranding />
            </ClientLayout>
          }
        />
        <Route
          path="/courceintegrationAPi"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CourceintegrationAPi />
            </ClientLayout>
          }
        />
        <Route
          path="/b2bTraining"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <B2bTraining />
            </ClientLayout>
          }
        />
        <Route
          path="/customerTraining"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CustomerTraining />
            </ClientLayout>
          }
        />
        <Route
          path="/continuingEducation"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ContinuingEducation />
            </ClientLayout>
          }
        />
        <Route
          path="/complianceTraining"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <ComplianceTraining />
            </ClientLayout>
          }
        />
        <Route
          path="/employeeDevelopment"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <EmployeeDevelopment />
            </ClientLayout>
          }
        />
        <Route
          path="/pricingPlans"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PricingPlans />
            </ClientLayout>
          }
        />
        <Route
          path="/CustomerStories"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CustomerStories />
            </ClientLayout>
          }
        />
        <Route
          path="/book"
          element={
            <ClientLayout
              head={{ title: "About Us", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Book />
            </ClientLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
