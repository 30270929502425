import { Col, Image, Layout, Row, Typography } from "antd";
import React, { useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { ImageUrl } from "../../helpers/Functions";

const faqs = new Array(7).fill({
    index: 1,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
    innerText:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
})

const FAQ = () => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Row
                className="blueBackground"
                style={{
                    height: "45vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <Typography.Title
                    className="fontFamily1"
                    style={{ fontSize: "30px", color: "#333d47", marginTop: 20, marginBottom: 0 }}
                >
                    {<> FAQ</>}
                </Typography.Title>
                <Typography.Text
                    className="fontFamily1"
                    style={{ fontSize: "18px", color: "#333d47", margin: 10 }}
                >
                    {<> Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro quibusdam tempora odit veritatis commodi</>}
                </Typography.Text>
            </Row>



            {/* section 2 */}
            <Row
                className="whiteBackground"
                style={{ backgroundColor: "white", justifyContent: "center" }}
            >
                <Col xs={24} md={20}>
                    <div className="flex justify-between gap-20 py-20">
                        <div className="w-1/2">

                            <Rows />
                        </div>
                        <div className="w-1/2 flex">
                            <div className="h-[450px] w-[350px]">

                                <Image
                                    alt={"Failed to load image"}
                                    preview={false}
                                    src={ImageUrl("faq.jpg")}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Layout>
    )
}

export default FAQ


const Rows = () => {
    const CustomRow = ({ faq }) => {
        const [expanded, setExpanded] = useState(false);

        const toggleExpand = () => {
            setExpanded(!expanded);
        };

        return (
            <Row justify="center">
                <Col xs={24} className="bg-white">
                    <div className="flex flex-col justify-center items-center bg-[#00A79D] bg-opacity-70 mb-4 rounded-[10px] overflow-hidden">
                        <div
                            className="flex justify-between items-center px-6 py-4 w-full cursor-pointer bg-[#228a83]"
                            onClick={toggleExpand}
                        >
                            <p className="font-popin text-2xl max-sm:text-xs text-white font-medium">
                                {faq.text}
                            </p>
                            <TiArrowSortedDown
                                className={`text-white min-w-[28px] min-h-[28px] pl-2 ${expanded ? "transform rotate-180" : ""
                                    }`}
                            />
                        </div>
                        <div
                            className={`border-t border-white ${expanded
                                ? "max-h-[1000px] opacity-100 transition-all duration-500 px-6 py-4"
                                : "max-h-0 opacity-0 transition-all duration-500"
                                }`}
                        >
                            <p className="font-euclid text-2xl max-sm:text-xs text-white font-normal leading-relaxed">
                                {faq.innerText}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <Row justify="center" className="py-12">
            <Col xs={24}>
                {faqs?.map((faq, index) => (
                    <CustomRow key={index} faq={faq} />
                ))}
            </Col>
        </Row>
    );
};