import React, { useState } from "react";
import { Col, Image, Layout, Row, Typography, Statistic, Tabs } from "antd";
import { BiBasket, BiAnalyse, BiCalendarEvent } from "react-icons/bi";
import styles from "./facility.module.css";
import { ImageUrl } from "../../helpers/Functions";
import { BsEnvelopeArrowDown } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import CountUp from "react-countup";
const formatter = (value) => <CountUp end={value} separator="," />;
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "What We Do",
    children: (
      <div className="tabs-content">
        <Row>
          <Col lg={24}>
            <h6 className="tab-green-text fontFamily2">Our Difference</h6>
            <h3 className="tab-black-heading fontFamily1">What We Do</h3>
            <p className="p-black fontFamily2">
              Proin ac lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
              facilisis vel mollis vitae, mollis nec ante.
            </p>
            <ul>
              <li>
                <FaCheck /> Lorem ipsum dolor sit amet consectetur adipiscing
              </li>
              <li>
                <FaCheck /> Nulla ante eros, venenatis vel malesuada sit amet
              </li>
              <li>
                <FaCheck /> Lorem ipscras maximus turpis eget erat
              </li>
              <li>
                <FaCheck /> Vestibulum vitae libero neque
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "2",
    label: "Education Centre",
    children: (
      <div className="tabs-content">
        <Row>
          <Col lg={24}>
            <h6 className="tab-green-text fontFamily2">Our Difference</h6>
            <h3 className="tab-black-heading fontFamily1">Education Centre</h3>
            <p className="p-black fontFamily2">
              Proin ac lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
              facilisis vel mollis vitae, mollis nec ante.
            </p>
            <ul>
              <li>
                <FaCheck /> Lorem ipsum dolor sit amet consectetur adipiscing
              </li>
              <li>
                <FaCheck /> Nulla ante eros, venenatis vel malesuada sit amet
              </li>
              <li>
                <FaCheck /> Lorem ipscras maximus turpis eget erat
              </li>
              <li>
                <FaCheck /> Vestibulum vitae libero neque
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "3",
    label: "We’re Already There",
    children: (
      <div className="tabs-content">
        <Row>
          <Col lg={24}>
            <h6 className="tab-green-text fontFamily2">Our Difference</h6>
            <h3 className="tab-black-heading fontFamily1">
              We’re already there
            </h3>
            <p className="p-black fontFamily2">
              Proin ac lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
              facilisis vel mollis vitae, mollis nec ante.
            </p>
            <ul>
              <li>
                <FaCheck /> Lorem ipsum dolor sit amet consectetur adipiscing
              </li>
              <li>
                <FaCheck /> Nulla ante eros, venenatis vel malesuada sit amet
              </li>
              <li>
                <FaCheck /> Lorem ipscras maximus turpis eget erat
              </li>
              <li>
                <FaCheck /> Vestibulum vitae libero neque
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "4",
    label: "Your Financial Bridge",
    children: (
      <div className="tabs-content">
        <Row>
          <Col lg={24}>
            <h6 className="tab-green-text fontFamily2">Our Difference</h6>
            <h3 className="tab-black-heading fontFamily1">
              Your Financial Bridge
            </h3>
            <p className="p-black fontFamily2">
              Proin ac lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
              facilisis vel mollis vitae, mollis nec ante.
            </p>
            <ul>
              <li>
                <FaCheck /> Lorem ipsum dolor sit amet consectetur adipiscing
              </li>
              <li>
                <FaCheck /> Nulla ante eros, venenatis vel malesuada sit amet
              </li>
              <li>
                <FaCheck /> Lorem ipscras maximus turpis eget erat
              </li>
              <li>
                <FaCheck /> Vestibulum vitae libero neque
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "5",
    label: "Word-Class Experts",
    children: (
      <div className="tabs-content">
        <Row>
          <Col lg={24}>
            <h6 className="tab-green-text fontFamily2">Our Difference</h6>
            <h3 className="tab-black-heading fontFamily1">
              Word-Class Experts
            </h3>
            <p className="p-black fontFamily2">
              Proin ac lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
              facilisis vel mollis vitae, mollis nec ante.
            </p>
            <ul>
              <li>
                <FaCheck /> Lorem ipsum dolor sit amet consectetur adipiscing
              </li>
              <li>
                <FaCheck /> Nulla ante eros, venenatis vel malesuada sit amet
              </li>
              <li>
                <FaCheck /> Lorem ipscras maximus turpis eget erat
              </li>
              <li>
                <FaCheck /> Vestibulum vitae libero neque
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    ),
  },
];
const Facility = () => {
  const [tabPosition, setTabPosition] = useState("left");
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          {<> Facilities</>}
        </Typography.Title>
        <Typography.Text
          className="fontFamily1"
          style={{ fontSize: "18px", color: "#333d47", margin: 10 }}
        >
          {
            <>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              quibusdam tempora odit veritatis commodi
            </>
          }
        </Typography.Text>
      </Row>

      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px 0",
        }}
      >
        <Col xs={23} md={14} className="my-3" style={{ textAlign: "center" }}>
          <p className="fontFamily1 f-35" style={{ textAlign: "center" }}>
            Our Facilities
          </p>
          <p className="fontFamily1 f-16">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
            consequuntur debitis quo qui? Corrupti quibusdam, minus ipsam
            fugiat.Lorem ipsum dolor sit amet consectetur adipisicing elit
          </p>
        </Col>
      </Row>

      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" , padding: "50px 0",}}
      >
        <Col xs={23} md={18} className="my-3" style={{ textAlign: "center" }}>
          <Row gutter={[16, 16]}>
            <Col lg={8}>
              <Cards
                icon={
                  <img
                    alt=""
                    src={ImageUrl("notf-icon.png")}
                    style={{ margin: "0 auto" }}
                  />
                }
                heading="Notifications & Emails"
              />
            </Col>
            <Col lg={8}>
              <Cards
                icon={
                  <img
                    alt=""
                    src={ImageUrl("i2.png")}
                    style={{ margin: "0 auto" }}
                  />
                }
                heading="Certification"
              />
            </Col>
            <Col lg={8}>
              <Cards
                icon={
                  <img
                    alt=""
                    src={ImageUrl("i3.png")}
                    style={{ margin: "0 auto" }}
                  />
                }
                heading="Campus"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="" style={{ justifyContent: "center" }}>
        <Col xs={24} md={24}>
          <div className="facility-baner">
            <Row
              gutter={[16, 16]}
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Col lg={18}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Col lg={12}>
                    <Image
                      alt={"Failed to load image"}
                      preview={false}
                      src={ImageUrl("v-pic.jpg")}
                      width="18"
                      height="16"
                    />
                  </Col>
                  <Col lg={11}>
                    <div>
                      <h4 className="f-32">
                        Lorem ipsum dolor sit amet, consectetur elit
                      </h4>
                      <h6 className="f-22">
                        Proin ac lobortis arcu, a vestibulum augue. Vivamus
                        ipsum, facilisis vel mollis vitae, mollis nec ante.
                        Quisque aliquam dictumfacilisis vel mollis vitae.
                      </h6>
                      <div className="counter">
                        <div>
                          <Statistic value={89} formatter={formatter} />
                          <h5 className="f-24">Scheduled Events</h5>
                        </div>
                        <div>
                          <Statistic value={56} formatter={formatter} />
                          <h5 className="f-24">Scheduled Events</h5>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="" style={{ justifyContent: "center" }}>
        <Col xs={23} md={18}>
          <div className="tab-page">
            <Tabs
              tabPosition={tabPosition}
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default Facility;

const Cards = ({ icon, heading }) => (
  <div className="facility-cards">
    {icon}
    <div className="text-center my-5">
      <p className="fontFamily1 f-30 py-3">{heading}</p>
      <p className="fontFamily1 f-16">
        Lorem ipsum dolor sit ametcon, sectetur adipiscing elit. Phasellus vehic
        sagittis euismod.
      </p>
    </div>
  </div>
);
