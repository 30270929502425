import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Skeleton,
  TimePicker,
  Typography,
  Upload
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import { Post } from "../../config/api/post";
import { CONTENT_TYPE } from "../../config/constants";
import { MEETING, UPLOADS_URL } from "../../config/constants/api";

import {
  FaLongArrowAltLeft
} from "react-icons/fa";
const { TextArea } = Input;
const { Option } = Select;

function MeetingDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [meeting, setMeeting] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();


  useEffect(() => {
    getMeeting();
  }, []);

  const getMeeting = async () => {
    setLoading(true);
    const res = await Get(
      `${MEETING.getMeetingById}${id}`,
      token
    );

    console.log("<<<<", meeting)

    setMeeting(res.data.meeting);
    debugger
    setLoading(false);
  };


  const handleStatus = async () => {
    try {
      const response = await Get(
        MEETING.toggleStatus + "/" + meeting._id,
        token,
        {}
      );
      const newUser = { ...meeting };

      newUser.isActive = !meeting.isActive;
      setModalOpen(false);
      setMeeting(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {

    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }


    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    Post(MEETING.updateMeeting + id, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Meeting Updated Successfully", "success");
          getMeeting()

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const deleteMeeting = async () => {
    try {
      const response = await Get(MEETING.deleteMeeting + id, token);
      setLoading(false);
      console.log("response22", response);
      if (response?.status) {
        swal("Success", "Meeting Deleted Successfully", 'success')
        navigate(-1)
      } else {

        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }



  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          className="blueBackground"
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title
            className="fontFamily1"
            style={{ fontSize: "30px", color: "black" }}
          >
            {editMode ? "Edit Meeting Details" : "View Meeting Details"}

          </Typography.Title>

          <Button style={{ width: "40px", height: "40px", background: "#7cc059", display: 'flex', justifyContent: 'center', alignItems: 'center', color: "white", position: "absolute", left: 150, cursor: "pointer", marginTop: 20, padding: 10, border: 'none', borderRadius: '100px' }} onClick={() => navigate(-1)}>

            <FaLongArrowAltLeft style={{ fontSize: "30px", color: "white" }} />
          </Button>
        </Row>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={22}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "100px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >

              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Skeleton active paragraph={{ rows: 10 }} />
                </div>
              )
              }

              {!loading && meeting && (<>


                <Form
                  layout="vertical"
                  name="basic"
                  className="contactForm"
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row style={{ padding: "20px" }}>
                    <Col xs={24} md={18}>

                      {editMode ? (
                        <>
                          <Row gutter={20}>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Title :
                              </Typography.Title>

                              <Form.Item
                                name="title"
                                initialValue={meeting?.title}
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input meeting title",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Title"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Fee :
                              </Typography.Title>

                              <Form.Item
                                name="charges"
                                initialValue={meeting?.charges}
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Price",
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: "100%", padding: '5px' }}
                                  size="large"
                                  placeholder="Enter Meeting Fee"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                          </Row>



                          <Row gutter={20}>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Meeting Date :
                              </Typography.Title>

                              <Form.Item
                                name="meetingDate"
                                initialValue={dayjs(meeting?.meetingDate)}
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select meeting Date",
                                  },
                                ]}
                              >
                                <DatePicker style={{ width: "100%" }} size="large"
                                  placeholder="Enter Meeting Date"
                                  className="signupFormInput" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Meeting Time :
                              </Typography.Title>

                              <Form.Item
                                name="meetingTime"
                                initialValue={dayjs(meeting?.meetingTime)}
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select meeting Time",
                                  },
                                ]}
                              >
                                <TimePicker style={{ width: "100%" }} size="large"
                                  placeholder="Enter meeting Time"
                                  className="signupFormInput" />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={20}>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Meeting Link :
                              </Typography.Title>

                              <Form.Item
                                name="meetingLink"
                                initialValue={meeting?.title}
                                style={{ width: "100%" }}

                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Meeting Link"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Meeting Id :
                              </Typography.Title>

                              <Form.Item
                                name="meetingId"
                                initialValue={meeting?.meetingId}
                                style={{ width: "100%" }}

                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Meeting Id"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              xs={24}
                              sm={12}
                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Passcode :
                              </Typography.Title>

                              <Form.Item
                                name="passcode"
                                initialValue={meeting?.passcode}
                                style={{ width: "100%" }}

                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Meeting Passcode"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                          </Row>


                          <Row gutter={20}>
                            <Col
                              xs={24}

                              style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}
                            >
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  textAlign: "left",
                                  marginBottom: 20,
                                }}
                              >
                                Description:
                              </Typography.Title>

                              <Form.Item
                                name="description"
                                initialValue={meeting?.description}
                                style={{ width: "100%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Meeting description",
                                  },
                                ]}
                              >
                                <TextArea
                                  rows={5}
                                  size="large"
                                  placeholder="Enter Description"
                                  className="signupFormInput2"
                                />
                              </Form.Item>
                            </Col>

                          </Row>






                        </>
                      ) : (
                        <>
                          <Row style={{ padding: "10px" }}>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Meeting Title{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.title}
                              </h5>
                            </Col>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Description
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.description}
                              </h5>
                            </Col>

                          </Row>

                          <br />

                          <Row style={{ padding: "10px" }}>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Meeting Date{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {dayjs(meeting?.meetingDate).format("MM/DD/YYYY")}

                              </h5>
                            </Col>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Meeting Time
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {dayjs(meeting?.meetingTime).format("HH:MM  A")}
                              </h5>
                            </Col>

                          </Row>
                          <br />
                          <Row style={{ padding: "10px" }}>

                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Meeting Link{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.meetingLink}
                              </h5>
                            </Col>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Passcode{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.meetingLink}
                              </h5>
                            </Col>
                          </Row>
                          <br />
                          <Row style={{ padding: "10px" }}>

                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Fee{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.charges}
                              </h5>
                            </Col>
                            <Col xs={24} md={12}>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  marginBottom: 10
                                }}
                              >
                                Status{" "}
                              </h5>
                              <h5
                                style={{
                                  display: "block",
                                  fontSize: 16,
                                  color: "#7a7e7f",
                                  fontWeight: "normal",
                                }}
                              >
                                {meeting?.status}
                              </h5>
                            </Col>
                          </Row>
                          <br />

                        </>
                      )}

                      <Row style={{ marginTop: 30 }}>
                        {editMode && (
                          <>
                            {" "}
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="loginButton"
                            >
                              Save Changes
                            </Button>
                            &emsp;
                            <Button
                              className="fontFamily1"
                              style={{
                                marginTop: "0px",
                                padding: "10px 30px",
                                cursor: "pointer",
                                color: "black",
                                borderRadius: "5px",
                                height: "auto",
                                border: "1px solid #203657",
                                fontWeight: "bold",
                              }}
                              ghost
                              size="large"
                              onClick={(e) => {
                                e.preventDefault();
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </Button>
                          </>
                        )}


                        {!editMode && (
                          <Row justify={"center"}>
                            <Col style={{ textAlign: "center" }}>
                              <Button
                                type="primary"
                                htmlType="button"
                                className="loginButton"
                                onClick={() => setEditMode(true)}
                              >
                                Edit Meeting
                              </Button> &emsp;
                              <Button
                                type="primary"
                                htmlType="button"
                                className="deleteButton"
                                onClick={() => deleteMeeting(true)}
                              >
                                Delete Meeting
                              </Button>
                              &emsp;
                              {/* <Button
                                className="fontFamily1"
                                style={{
                                  marginTop: "0px",
                                  padding: "10px 30px",
                                  cursor: "pointer",
                                  color: "black",
                                  borderRadius: "5px",
                                  height: "auto",
                                  border: "1px solid #203657",
                                  fontWeight: "bold",
                                }}
                                ghost
                                size="large"
                                onClick={() => window.open(UPLOADS_URL + "/" + meeting.file)}
                              >
                                Download File
                              </Button> */}
                            </Col>
                          </Row>
                        )}





                      </Row>
                    </Col>
                  </Row>
                </Form>


              </>)}





            </Card>
          </div>
        </Col>
      </Row>



    </Layout>
    //     <Layout className="configuration">
    //       <div className="boxDetails2">
    //         <Row style={{ padding: "10px 20px" }}>
    //           <Col
    //             xs={24}
    //             md={12}
    //             style={{ display: "flex", alignItems: "center" }}
    //           >
    //             <FaArrowLeft
    //               style={{ fontWeight: "bold", fontSize: "20px" }}
    //               onClick={() => navigate(-1)}
    //             />
    //             &emsp;
    //             <h1 className="pageTitle" style={{ margin: 0 }}>
    //               {editMode ? "Edit Meeting Details" : "View Meeting Details"}
    //             </h1>
    //           </Col>

    //         </Row>
    //         <br />

    //         {loading && (
    //           <div
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               height: "50vh",
    //             }}
    //           >
    //             <Skeleton active  paragraph={{ rows:10 }} />
    //           </div>
    //         )
    //             }

    //             {!loading && meeting && (<>


    //               <Form
    //           layout="vertical"
    //           name="basic"
    //           className="contactForm"
    //           labelCol={{
    //             span: 0,
    //           }}
    //           wrapperCol={{
    //             span: 24,
    //           }}
    //           initialValues={{
    //             remember: true,
    //           }}
    //           onFinish={onFinish}
    //           onFinishFailed={onFinishFailed}
    //           autoComplete="off"
    //         >
    //           <Row style={{ padding: "20px" }}>
    //             <Col xs={24} md={18}>
    //               <Row>
    //                 {editMode ? (
    //                   <Form.Item name="image">
    //                     <Upload
    //                       name="image"
    //                       showUploadList={false}
    //                       style={{ position: "relative" }}
    //                       beforeUpload={(file) => {
    //                         setImageNew(URL.createObjectURL(file));
    //                         return false;
    //                       }}
    //                     >
    //                       {" "}
    //                       <div
    //                         style={{
    //                           padding: "8px",
    //                           position: "absolute",
    //                           right: -10,
    //                           zIndex: 2,
    //                           bottom: 40,
    //                           backgroundColor: "#7CC059",
    //                           display: "flex",
    //                           maxWidth: "fit-content",
    //                           color: "white",
    //                           borderRadius: "20px",
    //                         }}
    //                       >
    //                         <TbCameraPlus />
    //                       </div>{" "}

    // <Image
    // preview={false}
    //                   src={imageNew
    //                     ? imageNew
    //                     : !meeting?.image
    //                     ? "/images/avatar.png"
    //                     : UPLOADS_URL + "/" + meeting?.image }
    //                   height={300}
    //                   width={500}
    //                   style={{ borderRadius: "10px", objectFit: "cover" }}
    //                 />
    //                     </Upload>
    //                   </Form.Item>
    //                 ) : (
    //                   <Image
    //                   src={!meeting.image ? "/images/avatar.png" : UPLOADS_URL + "/" + meeting.image }
    //                   height={300}
    //                   width={500}
    //                   style={{ borderRadius: "10px", objectFit: "cover" }}
    //                 />
    //                 )}
    //               </Row>

    //               <br />
    //               <br />

    //               {editMode ? (
    //                 <>
    //                   <Row gutter={20}>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Meeting Code :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="meetingCode"
    //                         initialValue={meeting?.meetingCode}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input your Meeting Code",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                           size="large"
    //                           placeholder="Enter MeetingCode"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Title :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="title"
    //                         initialValue={meeting?.title}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input meeting title",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                           size="large"
    //                           placeholder="Enter Title"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                   </Row>

    //                   <Row gutter={20}>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                        Description:
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="description"
    //                         initialValue={meeting?.description}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input Meeting description",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                           size="large"
    //                           placeholder="Enter Description"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Category :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="category"
    //                         initialValue={meeting?.category._id}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input meeting category",
    //                           },
    //                         ]}
    //                       >
    //                          <Select
    //            placeholder="Select Category"
    //            className="signupSelectBox"
    //         >
    //           {categories.map((item,index) => {
    //             return(<Option value={item._id}>{item.title}</Option>)
    //           })}

    //         </Select>
    //                       </Form.Item>
    //                     </Col>
    //                   </Row>

    //                   <Row gutter={20}>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                        Start Date :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="startDate"
    //                         initialValue={dayjs(meeting?.startDate)}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input start Date",
    //                           },
    //                         ]}
    //                       >
    //                         <DatePicker  style={{width:"100%"}} size="large"
    //                           placeholder="Enter start Date"
    //                           className="signupFormInput"/>
    //                       </Form.Item>
    //                     </Col>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Duration :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="duration"
    //                         initialValue={meeting?.duration}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input meeting duration",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                           size="large"
    //                           placeholder="Enter Duration"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                   </Row>

    //                   <Row gutter={20}>
    //                   <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Price :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="price"
    //                         initialValue={meeting?.price}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input price",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                         prefix="$"
    //                           size="large"
    //                           placeholder="Enter Price"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>
    //                     <Col
    //                       xs={24}
    //                       sm={12}
    //                       style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
    //                     >
    //                       <Typography.Title
    //                         className="fontFamily1"
    //                         style={{
    //                           fontSize: "16px",
    //                           fontWeight: 600,
    //                           color: "black",
    //                           textAlign: "left",
    //                           marginBottom: 20,
    //                         }}
    //                       >
    //                         Features :
    //                       </Typography.Title>

    //                       <Form.Item
    //                         name="features"
    //                         initialValue={meeting?.features}
    //                         style={{ width: "100%" }}
    //                         rules={[
    //                           {
    //                             required: true,
    //                             message: "Please input Features",
    //                           },
    //                         ]}
    //                       >
    //                         <Input
    //                           size="large"
    //                           placeholder="Enter Features"
    //                           className="signupFormInput"
    //                         />
    //                       </Form.Item>
    //                     </Col>

    //                   </Row>






    //                 </>
    //               ) : (
    //                 <>
    //                      <Row style={{ padding: "10px" }}>
    //             <Col xs={24} md={12}>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Meeting Code{" "}
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {meeting?.meetingCode}
    //                 </h5>
    //               </Col>
    //               <Col xs={24} md={12}>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Title{" "}
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {meeting?.title}
    //                 </h5>
    //               </Col>

    //             </Row>

    //             <br/>
    //             <Row style={{ padding: "10px" }}>
    //             <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Description{" "}
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {meeting?.description}
    //                 </h5>
    //               </Col>
    //               <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Category
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {meeting?.category.title}
    //                 </h5>
    //               </Col>
    //             </Row>
    //             <br/>

    //             <Row style={{ padding: "10px" }}>
    //               <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Start Date{" "}
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {dayjs(meeting?.startDate).format("MM/DD/YYYY")}

    //                 </h5>
    //               </Col>
    //               <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Duration{" "}
    //                 </h5>
    //                 <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                   }}
    //                 >
    //                   {meeting?.duration}
    //                 </h5>
    //               </Col>

    //             </Row>
    //             <br/>
    //             <Row style={{ padding: "10px" }}>
    //             <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Price{" "}
    //                 </h5>
    // <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                     margin:"10px 0px"
    //                   }}
    //                 >
    //                  $ {meeting.price}

    //                 </h5>

    //               </Col>
    //               <Col xs={24} md={12}>
    //               <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     fontWeight: "bold",
    //                     marginBottom:10
    //                   }}
    //                 >
    //                   Features{" "}
    //                 </h5>
    //                 {meeting?.features.length > 0 && meeting?.features.toString().split(",").map(item => { return( <h5
    //                   style={{
    //                     display: "block",
    //                     fontSize: 16,
    //                     color: "#7a7e7f",
    //                     fontWeight: "normal",
    //                     margin:"10px 0px"
    //                   }}
    //                 >
    //                   {item}

    //                 </h5>)})}

    //               </Col>

    //             </Row>
    //                 </>
    //               )}

    //               <Row style={{ marginTop: 30 }}>
    //                 {editMode && (
    //                   <>
    //                     {" "}
    //                     <Button
    //                       type="primary"
    //                       htmlType="submit"
    //                       className="loginButton"
    //                     >
    //                       Save Changes
    //                     </Button>
    //                     &emsp;
    //                     <Button
    //                       className="fontFamily1"
    //                       style={{
    //                         marginTop: "0px",
    //                         padding: "10px 30px",
    //                         cursor: "pointer",
    //                         color: "black",
    //                         borderRadius: "5px",
    //                         height: "auto",
    //                         border: "1px solid #203657",
    //                         fontWeight: "bold",
    //                       }}
    //                       ghost
    //                       size="large"
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         setEditMode(false);
    //                       }}
    //                     >
    //                       Cancel
    //                     </Button>
    //                   </>
    //                 )}
    //                 {!editMode && (
    //                   <Row justify={"center"}>
    //                     <Col style={{ textAlign: "center" }}>
    //                       <Button
    //                         type="primary"
    //                         htmlType="button"
    //                         className="loginButton"
    //                         onClick={() => setEditMode(true)}
    //                       >
    //                         Edit Meeting
    //                       </Button>
    //                     </Col>
    //                   </Row>
    //                 )}
    //               </Row>
    //             </Col>
    //           </Row>
    //         </Form>


    //             </>)}



    //         <br />
    //         <br />
    //       </div>

    //       <Modal
    //         open={modalOpen}
    //         onOk={() => handleStatus()}
    //         onCancel={() => setModalOpen(false)}
    //         okText="Yes"
    //         className="StyledModal"
    //         style={{
    //           left: 0,
    //           right: 0,
    //           marginLeft: "auto",
    //           marginRight: "auto",
    //           textAlign: "center",
    //         }}
    //         cancelText="No"
    //         cancelButtonProps={{
    //           style: {
    //             border: "2px solid #203453",
    //             color: "#203453",
    //             height: "auto",
    //             padding: "6px 35px",
    //             borderRadius: "50px",
    //             fontSize: "16px",
    //             marginTop: "15px",
    //           },
    //         }}
    //         okButtonProps={{
    //           style: {
    //             backgroundColor: "#203453",
    //             color: "white",
    //             marginTop: "15px",
    //             height: "auto",
    //             padding: "5px 35px",
    //             borderRadius: "50px",
    //             fontSize: "16px",
    //             border: "2px solid #203453",
    //           },
    //         }}
    //       >
    //         <Image
    //           src="../images/question.png"
    //           preview={false}
    //           width={100}
    //           height={120}
    //         />
    //         <Typography.Title level={4} style={{ fontSize: "25px" }}>
    //           {meeting?.isActive ? "Deactivate" : "Activate"}
    //         </Typography.Title>
    //         <Typography.Text style={{ fontSize: 16 }}>
    //         Do You Want To  {meeting?.isActive ? "Deactivate" : "Activate"} This User?
    //         </Typography.Text>
    //       </Modal>


    //       <br />
    //       <br />
    //     </Layout>
  );
}
export default MeetingDetails;
